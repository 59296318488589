<template>
  <section class="wrapper">
    <div class="container">
      <Title :pretitle="`${entityType} ${entityName}`" title="Tickets"></Title>

      <div class="tickets">
        <TicketsTicket v-for="ticket in tickets" :ticket="ticket" />
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { defineComponent, onBeforeMount, ref, toRaw } from "vue";
import Title from "@/components/layout/Title.vue";
import TicketsTicket from "@/views/tickets/components/TicketsTicket.vue";
import { useStore } from "@/store";
import { Ticket } from "@/models/tienda/Ticket";

export default defineComponent({
  name: "Tickets",
  components: {
    Title,
    TicketsTicket,
  },
  setup() {
    const store = useStore();
    const tickets = ref([] as Array<Ticket[]>);

    onBeforeMount(async () => {
      tickets.value = await store.dispatch("user/getTickets");
      console.log(toRaw(tickets.value));
    });

    return {
      entityName: store.state.entityName,
      entityType: store.state.entityType,
      tickets
    };
  },
});
</script>

<style lang=""></style>
