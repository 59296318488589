
import { defineComponent, PropType } from "vue";

export default defineComponent({
  name: "TicketsTicketSummary",
  components: {},
  props: {
    summary: {
      type: Object as PropType<{
        descripcion: string,
        total: number
      }>,
      required: true
    }
  },
});
