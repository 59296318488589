<template>
  <section class="wrapper">
    <div class="container">
      <Title :pretitle="username" title="Datos personales"></Title>
      <PerfilFicha :perfil="perfil"></PerfilFicha>
      <!-- <PerfilPagos></PerfilPagos> -->
      <PerfilPassword></PerfilPassword>
      <PerfilContacto></PerfilContacto>
    </div>
  </section>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";

import Title from "@/components/layout/Title.vue";
import PerfilFicha from "@/views/perfil/components/PerfilFicha.vue";
import PerfilPagos from "@/views/perfil/components/PerfilPagos.vue";
import PerfilPassword from "@/views/perfil/components/PerfilPassword.vue";
import PerfilContacto from "@/views/perfil/components/PerfilContacto.vue";
import TopBar from "@/components/layout/TopBar.vue";
import { useStore } from "@/store";

export default defineComponent({
  name: "Perfil",
  components: {
    Title,
    TopBar,
    PerfilFicha,
    PerfilPagos,
    PerfilPassword,
    PerfilContacto,
  },
  setup() {
    const store = useStore();

    return {
      username: computed(
        () =>
          `${store.state.user.perfil.nombre} ${store.state.user.perfil.apellido_1}`
      ),
      perfil: computed(() => store.state.user.perfil),
    };
  },
});
</script>

<style lang="scss"></style>
