<template>
  <div class="comming">
    <div class="comming__ico">
      <img :src="require(`@/assets/ico/${imageUrl}`)" alt="" />
    </div>
    <div class="comming__content">
      {{ msgComming }}
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "Nota",
  props: {
    msgComming: String,
    imageUrl: String,
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/scss/base/_variables.scss";

.comming {
  border-radius: 1rem;
  box-shadow: $box-shadow-cards;
  margin: 1rem 0;
  width: 100%;
  padding: 2rem;
  background: $color-blanco;
  text-align: center;
  &__ico {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1.5rem;
    img {
      width: 120px;
      height: auto;
      margin-right: 8px;
    }
  }

  &__content {
    font-size: 1.2rem;
  }
}
</style>
