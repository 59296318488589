import { InjectionKey } from 'vue';
import { createStore, useStore as baseUseStore, Store } from 'vuex'
import { TokenStorage } from '@/services/tokenStorage';

import authModule from './modules/auth';
import userModule from './modules/user';
import { AuthState } from './modules/auth/interfaces';
import { UserState } from './modules/user/interfaces';

export interface State {
  entityName: string,
  entityType: string,
  assetsUrl: string,
  isLoading: boolean,
  auth: AuthState,
  user: UserState
}

export const key: InjectionKey<Store<any>> = Symbol();


export const initialState = (): State => ({
  entityName: process.env.VUE_APP_ENTITY_NAME,
  entityType: process.env.VUE_APP_ENTITY_TYPE,
  assetsUrl: process.env.VUE_APP_ASSETS_URL,
  isLoading: false,
  auth: {} as AuthState,
  user: {} as UserState
})

export const store = createStore({
  modules: {
    auth: authModule,
    user: userModule
  },
  state: initialState(),
  getters: {
    isAuthenticated() {
      return TokenStorage.isAuthenticated();
    }
  },
  actions: {
    clearAllState({ commit }) {
      commit('clearState');
    }
  },
  mutations: {
    changeAppLoadingState: (state, payload) => {
      state.isLoading = payload;
    },
    clearState: (state) => {
      Object.assign(state, initialState())
    }
  }
})

export function useStore() {
  return baseUseStore<State>(key);
}
