<template>
  <section class="wrapper">
    <div class="container">
      <Title :pretitle="username" title="Perfil familiar"></Title>

      <p v-if="familia.length > 0">
        Actualmente tienes {{ familia.length > 1 ? 'configurados' : 'configurado' }}
        <strong>{{ familia.length }} {{ familia.length > 1 ? 'miembros' : 'miembro' }}</strong> de la familia.
      </p>
      <p v-else >Actualmente no tienes configurados miembros de la familia.</p>

      <div class="add-familiar mb-4">
        <Modal id="modaladd">
          <template #titulo>
            <h5 class="modal-title">
              Añade familiares
            </h5>
          </template>
          <PerfilAddFamiliar ref="modalAddFamiliar"></PerfilAddFamiliar>
          <template #footer>
            <button
              type="button"
              class="boton boton--principal"
              data-bs-dismiss="modal"
              @click="modalAddFamiliar.guardarFamiliar()"
            >
              Cerrar y guardar
            </button>
            <!-- <button type="button" class="boton boton--principal">
              Guardar cambios
            </button> -->
          </template>
        </Modal>
        <button
          type="button"
          class="boton boton--principal boton--block"
          data-bs-toggle="modal"
          data-bs-target="#modaladd"
        >
          <i class="bi bi-plus-circle-fill me-2"></i> Añadir socio familiar
        </button>
      </div>

      <PerfilFicha
        v-for="user in familia"
        :perfil="user"
        :isFamiliar="true"
        :key="user.id"
      />
    </div>
  </section>
</template>

<script lang="ts">
import { computed, defineComponent, reactive, ref, watch } from "vue";
import Title from "@/components/layout/Title.vue";
import PerfilFicha from "@/views/perfil/components/PerfilFicha.vue";
import PerfilAddFamiliar from "@/views/perfil/components/PerfilAddFamiliar.vue";
import Modal from "@/components/ui/Modal.vue";
import { useStore } from "@/store";

export default defineComponent({
  name: "PerfilFamiliar",
  components: {
    Title,
    PerfilFicha,
    Modal,
    PerfilAddFamiliar,
  },
  setup() {
    const store = useStore();
    const username = computed(
      () =>
        `${store.state.user.perfil.nombre} ${store.state.user.perfil.apellido_1}`
    );
    const modalAddFamiliar = ref();

    // Obtener familiares
    store.dispatch("user/getFamilia");

    return {
      username,
      familia: computed(() => store.state.user.familia),
      modalAddFamiliar,
    };
  },
});
</script>

<style lang="scss"></style>
