
import { defineComponent } from "vue";
import Title from "@/components/layout/Title.vue";
import { useStore } from "@/store";
export default defineComponent({
  name: "Privacidad",
  components: {
    Title,
  },
  setup() {
    const store = useStore();

    return {
      entityName: store.state.entityName,
      entityType: store.state.entityType,
    };
  },
});
