
import { defineComponent } from "vue";

export default defineComponent({
  name: "Nota",
  props: {
    msgNota: String,
    msgNotaTitutlo: String,
  },
});
