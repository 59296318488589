import { State } from "@/store";
import { Module } from "vuex";

import { AuthState } from "./interfaces";
import state from "./state";
import * as mutations from "./mutations";
import * as actions from "./actions";


const authModule: Module<AuthState, State> = {
    namespaced: true,
    state,
    mutations,
    actions
}

export default authModule;
