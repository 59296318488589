<template>
  <div class="wrapper">
    <div class="container">
      <Title
        pretitle="Política de privacidad"
        :title="`${entityType} ${entityName}`"
      ></Title>
      <div class="post">
        <!-- Texto de política de privacidad ///////////////////////////////////// -->
        <!-- Texto de política de privacidad ///////////////////////////////////// -->
        <!-- Texto de política de privacidad ///////////////////////////////////// -->

        <router-link to="/" class="boton boton--principal mb-4"
          ><i class="bi bi-chevron-left"></i> Volver atrás</router-link
        >

        <h2>Titular de la web</h2>

        <ul>
          <li>
            <strong>Su denominación social es:</strong> Asoc Comparsa Tot A Orri
          </li>
          <li><strong>Su CIF:</strong> G12661658</li>
          <li>
            <strong>Su domicilio social es:</strong> BISBE LASSALA, Nº 41 12500,
            Vinaròs (CASTELLÓN) — España
          </li>
          <li><strong>Su actividad social es:</strong> Asociación cultural</li>
          <li><strong>Correo electrónico:</strong> totaorri@gmail.com</li>
          <li><strong>Finalidad de sitio web:</strong> Gestión cultural</li>
          <li>
            <strong
              >Páginas de Asoc Comparsa Tot A Orri (en adelante Comparsa Tot A
              Orri):</strong
            >
            https://www.totaorri.es y https://socios.totaorri.es
          </li>
        </ul>

        <hr />

        <h2>Protección de datos personales</h2>
        <p><strong>Responsable del tratamiento</strong></p>

        <p>
          <strong>Datos de contacto del responsable:</strong> Asoc Comparsa Tot
          A Orri y correo electrónico de contacto totaorri@gmail.com
        </p>

        <hr />

        <h2>Tus derechos de protección de datos</h2>

        <p>
          Cómo ejercitar tus derechos: Puedes dirigir una comunicación por
          escrito al domicilio social de Comparsa Tot A Orri o a la dirección de
          correo electrónico indicado en el encabezamiento de este aviso legal,
          incluyendo en ambos casos fotocopia de su DNI u otro documento
          identificativo similar, para solicitar el ejercicio de los derechos
          siguientes:
        </p>

        <ul>
          <li>
            Derecho a solicitar el acceso a los datos personales: podrás
            preguntar a Comparsa Tot A Orri si esta empresa está tratando tus
            datos.
          </li>
          <li>
            Derecho a solicitar su rectificación (en caso de que sean
            incorrectos).
          </li>
          <li>
            Derecho a solicitar la limitación de su tratamiento, en cuyo caso
            únicamente serán conservados por Comparsa Tot A Orri para el
            ejercicio o la defensa de reclamaciones.
          </li>
          <li>
            Derecho a oponerte al tratamiento: Comparsa Tot A Orri dejará de
            tratar los datos en la forma que indiques, salvo que por motivos
            legítimos imperiosos o el ejercicio o la defensa de posibles
            reclamaciones se tengan que seguir tratando.
          </li>
          <li>
            Derecho a la portabilidad de los datos: en caso de que quieras que
            tus datos sean tratados por otra firma, Comparsa Tot A Orri te
            facilitará la portabilidad de tus datos al nuevo responsable.
          </li>
          <li>
            Derecho al borrado de los datos: y salvo imperativo legal se
            borrarán tras tu confirmación.
          </li>
        </ul>

        <p>
          <strong
            >Modelos, formularios y más información sobre tus derechos:
          </strong>
          <a
            href="https://www.agpd.es/portalwebAGPD/CanalDelCiudadano/derechos/index-ides-idphp.php"
            >Página oficial de la Agencia Española de Protección de Datos</a
          >
        </p>

        <p>
          <strong>Posibilidad de retirar el consentimiento:</strong> en el caso
          de que hayas otorgado el consentimiento para alguna finalidad
          específica, tienes derecho a retirarlo en cualquier momento, sin que
          ello afecte a la licitud del tratamiento basado en el consentimiento
          previo a su retirada.
        </p>

        <p>
          <strong>Cómo reclamar ante la Autoridad de Control:</strong> Si
          consideras que hay un problema con la forma en que Comparsa Tot A Orri
          está manejando tus datos, puedes dirigir tus reclamaciones al
          Responsable de Seguridad de Comparsa Tot A Orri (indicado arriba) o a
          la
          <a
            href="https://www.agpd.es/portalwebAGPD/enlaces/index-ides-idphp.php"
            >autoridad de protección de datos</a
          >
          que corresponda, siendo la
          <a href="https://www.agpd.es/"
            >Agencia Española de Protección de Datos</a
          >, la indicada en el caso de España.
        </p>

        <h3>Derecho al olvido y acceso a tus datos personales</h3>

        <p>
          En todo momento tendrás derecho a revisar, recuperar, anonimizar y/o
          borrar, total o parcialmente, los datos almacenados en el Sitio Web.
          Solo tienes que enviar un correo electrónico a y solicitarlo. O puedes
          hacerlo aquí mismo:
        </p>

        <h3>Conservación de los datos</h3>

        <p>
          <strong>Datos desagregados:</strong> Los datos desagregados serán
          conservados sin plazo de supresión. Datos de los clientes de la tienda
          online: El periodo de conservación de los datos personales variará en
          función del servicio que el cliente contrate en nuestra tienda online.
          En cualquier caso, será el mínimo necesario, pudiendo mantenerse
          hasta:
        </p>

        <ul>
          <li>
            4 años: Ley sobre Infracciones y Sanciones en el Orden Social
            (obligaciones en materia de afiliación, altas, bajas, cotización,
            pago de salarios…); Arts. 66 y sig. Ley General Tributaria (libros
            de contabilidad…);
          </li>
          <li>
            5 años: Art. 1964 Código Civil (acciones personales sin plazo
            especial)
          </li>
          <li>
            6 años: Art. 30 Código de Comercio (libros de contabilidad,
            facturas…
          </li>
          <li>
            10 años: Art. 25 Ley de Prevención del Blanqueo de Capitales y
            Financiación del Terrorismo.
          </li>
        </ul>

        <p>
          <strong>Datos de los suscriptores al feed por e-mail:</strong> Desde
          que el usuario se suscribe hasta que se da de baja.
          <strong>Datos de los suscriptores a la newsletter:</strong> Desde que
          el usuario se suscribe hasta que se da de baja.
          <strong
            >Datos de usuarios subidos por Comparsa Tot A Orri a páginas y
            perfiles en redes sociales:</strong
          >
          Desde que el usuario ofrece su consentimiento hasta que lo retira.
        </p>

        <h3>Secreto y seguridad de los datos</h3>

        <p>
          Comparsa Tot A Orri se compromete en la utilización de los datos, a
          respetar su confidencialidad y a utilizarlos de acuerdo con la
          finalidad de los mismos, así como a dar cumplimiento a su obligación
          de guardarlos y adaptar todas las medidas para evitar la alteración,
          pérdida, tratamiento o acceso no autorizado, de conformidad con lo
          establecido en el Real Decreto 1720/2007 de 21 de diciembre por el que
          se aprueba el Reglamento de desarrollo de la Ley Orgánica 15/1999 de
          13 de diciembre, de Protección de Datos de Carácter Personal.
        </p>

        <p>
          Tú garantizas que los datos personales facilitados a través de los
          formulario son veraces, quedando obligado a comunicar cualquier
          modificación de los mismos.
        </p>

        <p>
          Igualmente, garantizas que toda la información facilitada corresponde
          con tu situación real, que está puesta al día y es exacta. Además
          debes mantener en todo momento tus datos actualizados, siendo el único
          responsable de la inexactitud o falsedad de los datos facilitados y de
          los perjuicios que pueda causar por ello a Comparsa Tot A Orri como
          titular de Asoc Comparsa Tot A Orri , o a terceros con motivo de la
          utilización de dicho.
        </p>

        <h3>Brechas de seguridad</h3>

        <p>
          Comparsa Tot A Orri adopta medidas de seguridad razonablemente
          adecuadas para detectar la existencia de virus, ataques de fuerza
          bruta e inyecciones de código.
        </p>

        <p>
          No obstante, debes ser consciente de que las medidas de seguridad de
          los sistemas informáticos en Internet no son enteramente fiables y
          que, por tanto Comparsa Tot A Orri no puede garantizar la inexistencia
          de virus u otros elementos que puedan producir alteraciones en los
          sistemas informáticos (software y hardware) del Usuario o en sus
          documentos electrónicos y ficheros contenidos en los mismos.
        </p>

        <p>
          A pesar de ello, para tratar de garantizar la seguridad y privacidad
          de tus datos personales, Asoc Comparsa Tot A Orri dispone de un
          sistema de vigilancia de seguridad activo que informa de cada
          actividad de los usuarios y posibles brechas en la seguridad de los
          datos de los usuarios. En caso de detectarse alguna brecha, Comparsa
          Tot A Orri se compromete a informar a los usuarios en el plazo máximo
          de 72 horas.
        </p>

        <h2>
          Qué información recabamos de los usuarios y para qué los utilizamos
        </h2>

        <p>
          Todos los productos y servicios ofertados en el sitio web Asoc
          Comparsa Tot A Orri remiten a formularios de contacto, formularios de
          comentarios y formularios para efectuar registros de usuario,
          suscripción a newsletter y/o pedidos de compra.
        </p>

        <p>
          Este sitio web siempre requiere el consentimiento previo de los
          usuarios para tratar sus datos personales con los fines indicados.
          Tienes derecho a revocar tu consentimiento previo en cualquier
          momento.
        </p>

        <h3>Registro de actividades de tratamiento de los datos</h3>

        <p>
          <strong>Web y hosting:</strong> Asoc Comparsa Tot A Orri cuenta con un
          cifrado SSL TLS v.1.2 que permite el envío seguro de datos personales
          a través de formularios de contacto de tipo estándar, alojados en los
          servidores que Comparsa Tot A Orri ha contratado a Dinahosting
        </p>

        <p>
          <strong>Datos recabados a través de la web:</strong> Los datos
          personales recogidos serán objeto de tratamiento automatizado e
          incorporados a los correspondientes ficheros de los que Comparsa Tot A
          Orri es titular.
        </p>

        <ul>
          <li>
            Nos llegará tu IP, que será usada para comprobar el origen del
            mensaje con objeto de ofrecerte información, protección contra
            comentarios SPAM y para detectar posibles irregularidades (por
            ejemplo: partes opuestas del mismo caso escriben en el sitio web
            desde la misma IP), así como datos relativos a su ISP.
          </li>
          <li>
            Asimismo, podrás facilitarnos tus datos a través correo electrónico
            y otros medios de comunicación indicados en la sección de contacto.
          </li>
        </ul>

        <p>
          <strong>Formulario de comentarios:</strong> En la web existe la
          posibilidad de que los usuarios dejen comentarios a las publicaciones
          del sitio. Existe una cookie que almacena los datos facilitados por el
          usuario para que no tenga que volver a introducirlos en cada nueva
          visita y además se recogen internamente la dirección de email, nombre,
          web y la dirección IP.
        </p>

        <p>Los datos están almacenados en los servidores de Dinahosting</p>

        <p>
          <strong>Registro de usuarios:</strong> No se admiten salvo petición
          expresa.
        </p>

        <p>
          <strong>Formulario de compra:</strong> Para acceder a los productos y
          servicios ofertados en nuestra tienda online, el usuario dispone de un
          formulario de compra sujeto a las condiciones de contratación
          especificadas en nuestra política comercial y condiciones de
          contratación donde se le requerirán datos de contacto y de pago.
        </p>

        <p>Los datos están almacenados en los servidores de Dinahosting</p>

        <p>
          Recopilamos información sobre ti durante el proceso de pago en nuestra
          tienda. Esta información puede incluir, y no solo esto, tu nombre,
          dirección, correo electrónico, teléfono, detalles de pago y otros
          necesarios para poder procesar tus pedidos. La gestión de estos datos
          nos permite:
        </p>

        <ul>
          <li>Enviarte información importante de tu cuenta/pedido/servicio.</li>
          <li>
            Responder a tus peticiones, quejas y solicitudes de reembolso.
          </li>
          <li>Procesar pagos y evitar transacciones fraudulentas.</li>
          <li>
            Configurar y administrar tu cuenta, darte servicio técnico y de
            cliente, y verificar tu identidad.
          </li>
        </ul>

        <p>
          Adicionalmente, puede que también recopilemos la siguiente
          información:
        </p>

        <ul>
          <li>
            Ubicación y datos de tráfico (incluida la dirección IP y el
            navegador) si haces un pedido, o si necesitamos estimar impuestos y
            costes de envío basados en tu ubicación.
          </li>
          <li>
            Páginas de producto visitadas y contenido visualizado mientras tu
            sesión está activa.
          </li>
          <li>Tus comentarios y reseñas de productos si eliges dejarlas .</li>
          <li>
            Dirección de envío si pides costes de envío ants de hacer la compra
            mientras tu sesión está activa.
          </li>
          <li>
            Cookies esenciales para hacer el seguimiento de los contenidos de tu
            carrito mientras tu sesión está activa.
          </li>
          <li>
            Correo y contraseña de tu cuenta para permitirte acceder a tu
            cuenta, si tienes una.
          </li>
          <li>
            Si creas una cuenta guardamos tu nombre, dirección y teléfono, para
            usarlos en tus futuros pedidos.
          </li>
        </ul>

        <p>
          <strong>Formularios de suscripción a Newsletter:</strong> Comparsa Tot
          A Orri utiliza el servicio de envío de newsletters (boletines por
          correo electrónico) de Mailchimp, que almacena tus datos de correo
          electrónico, nombre y aceptación de la suscripción. Puedes anular en
          cualquier momento la suscripción al boletín mediante un enlace
          específico situado al fondo de cada envío que recibas
        </p>

        <p>
          <strong>Correo electrónico:</strong> Nuestro prestador de servicios de
          correo electrónico es Google Inc.
        </p>

        <p>
          <strong>Mensajería instantánea:</strong> Comparsa Tot A Orri no presta
          servicio a través de mensajería instantánea como, por ejemplo,
          WhatsApp, Facebook Messenger o Line.
        </p>

        <p>
          <strong>Prestadores de servicios de pago:</strong> A través de Asoc
          Comparsa Tot A Orri, puedes acceder, por medio de enlaces, a sitios
          web de terceros, como PayPal o Stripe, para realizar pagos de los
          servicios prestados por Comparsa Tot A Orri. En ningún momento el
          personal de Comparsa Tot A Orri tiene acceso a los datos bancarios
          (por ejemplo, el número de tarjeta de crédito) que facilites a dichos
          terceros.
        </p>

        <h3>Contenido incrustado desde otras webs</h3>

        <p>
          Los artículos o productos de Asoc Comparsa Tot A Orri pueden incluir
          contenido incrustado (p.ej. vídeos, imágenes, artículos, etc.). El
          contenido incrustado desde otras webs se comporta del mismo modo que
          si el visitante ha visitado la otra web. Estas webs pueden recopilar
          datos sobre ti, usar cookies, incrustar seguimiento de terceros, y
          vigilar tu interacción con el contenido incrustado, incluido el
          seguimiento de tu interacción con el contenido incrustado si tienes
          una cuenta o estás conectado a esa web.
        </p>

        <p>
          <strong>Otros servicios:</strong> Ciertos servicios prestados a través
          del sitio web Asoc Comparsa Tot A Orri pueden contener condiciones
          particulares con previsiones específicas en materia de protección de
          datos personales. Se hace indispensable su lectura y aceptación con
          carácter previo a la solicitud del servicio de que se trate.
        </p>

        <p>
          <strong>Finalidad y legitimación:</strong> La finalidad del
          tratamiento de estos datos será únicamente la de prestarte la
          información o servicios que nos solicites.
        </p>

        <h3>Redes sociales</h3>

        <p>
          <strong>Presencia en redes:</strong> Comparsa Tot A Orri cuenta con
          perfiles en algunas de las principales redes sociales de Internet.
        </p>

        <p>
          <strong>Finalidad y legitimación:</strong> El tratamiento que Comparsa
          Tot A Orri llevará a cabo con los datos dentro de cada una de las
          referidas redes será, como máximo, el que la red social permita a los
          perfiles corporativos. Así pues, Comparsa Tot A Orri podrá informar,
          cuando la ley no lo prohíba, a sus seguidores por cualquier vía que la
          red social permita sobre sus actividades, ponencias, ofertas, así como
          prestar servicio personalizado de atención al cliente.
        </p>

        <p>
          <strong>Extracción de datos:</strong> En ningún caso Comparsa Tot A
          Orri extraerá datos de las redes sociales, a menos que se obtuviera
          puntual y expresamente el consentimiento del usuario para ello.
        </p>

        <p>
          <strong>Derechos:</strong> Cuando, debido a la propia naturaleza de
          las redes sociales, el ejercicio efectivo de los derechos de
          protección de datos del seguidor quede supeditado a la modificación
          del perfil personal de este, Comparsa Tot A Orri te ayudará y
          aconsejará a tal fin en la medida de sus posibilidades.
        </p>

        <h3>Encargados del tratamiento fuera de la UE</h3>

        <p>
          Almacenamiento y cifrado. Comparsa Tot A Orri utiliza servicios de
          almacenamiento cifrado de Google Inc a través de Google Drive.
        </p>

        <p>
          <strong>Correo electrónico.</strong> El servicio de correo electrónico
          de Comparsa Tot A Orri se presta usando los servicios de Google Inc. a
          través de Google Apps.
        </p>

        <p>
          <strong>Redes Sociales.</strong> Comparsa Tot A Orri hace uso de las
          redes sociales estadounidenses YouTube, Facebook y Twitter, a quien se
          realiza una transferencia internacional de datos, de tipo analíticos y
          técnicos en relación con el sitio web siendo en sus servidores en los
          que Comparsa Tot A Orri trata los datos que, a través de ellas, los
          usuarios, suscriptores o navegantes entregan a la firma Comparsa Tot A
          Orri o comparten con esta.
        </p>

        <p>
          <strong>Prestadores de pago.</strong> Para que puedas pagar a través
          de PayPal, Redsys o Stripe, Comparsa Tot A Orri enviará los datos
          estrictamente necesarios de aquellos a estos procesadores de pago para
          la emisión de la correspondiente solicitud de pago. Tu información
          está protegida de acuerdo a nuestra política de privacidad y cookies.
        </p>

        <p>
          Al activar una suscripción o facilitar tus datos de pago, comprendes y
          aceptas nuestra política de privacidad y cookies. Siempre tendrás
          derecho al acceso, rectificación, supresión, limitación, portabilidad
          y olvido de tus datos.
        </p>

        <p></p>
        <p>
          Desde el momento en que te registras como usuario en esta web,
          Comparsa Tot A Orri tiene acceso a:
        </p>
        Nombre de usuario y correo electrónico, dirección IP, dirección postal,
        DNI/CIF y datos de pago. En todo caso Comparsa Tot A Orri se reserva el
        derecho de modificar, en cualquier momento y sin necesidad de previo
        aviso, pero informando, la presentación y configuración del sitio web
        Asoc Comparsa Tot A Orri como el presente aviso legal.
        <p></p>

        <h3>Compromisos y obligaciones con nuestros usuarios</h3>

        <p>
          El acceso y/o uso de Asoc Comparsa Tot A Orri atribuye a quien lo
          realiza la condición de usuario, aceptando, desde este mismo momento,
          plenamente y sin reserva alguna, el presente aviso legal en relación
          con determinados servicios y contenidos de Asoc Comparsa Tot A Orri.
        </p>

        <p>
          En la utilización de Asoc Comparsa Tot A Orri el usuario se compromete
          a no llevar a cabo ninguna conducta que pudiera dañar la imagen, los
          intereses y los derechos de Comparsa Tot A Orri o de terceros o que
          pudiera dañar, inutilizar o sobrecargar el portal Asoc Comparsa Tot A
          Orri o que impidiera, de cualquier forma, la normal utilización de
          Asoc Comparsa Tot A Orri.
        </p>

        <h2>política de cookies</h2>
        <h3>¿Qué es una cookie?</h3>

        <p>
          Una cookie es un fichero inofensivo que se descarga en tu ordenador al
          acceder a determinadas páginas web. Las cookies permiten a una página
          web, entre otras cosas, almacenar y recuperar información sobre los
          hábitos de navegación de un usuario o de su equipo y, dependiendo de
          la información que contengan y de la forma en que utilice su equipo.
        </p>

        <p>
          Así, hay cookies que obtienen información relacionada con el número de
          páginas visitadas, la ciudad a la que está asignada la dirección IP
          desde la que se accede, el número de nuevos usuarios, la frecuencia y
          reincidencia de las visitas, el tiempo de visita, el navegador, el
          operador o el tipo de terminal desde el que se realiza la visita.
        </p>

        <h3>¿Qué NO ES una cookie?</h3>

        <p>
          No es un virus, ni un troyano, ni un gusano, ni spam, ni spyware, ni
          abre ventanas pop-up.
        </p>

        <h3>¿Qué información almacena una cookie?</h3>

        <p>
          Las cookies no suelen almacenar información sensible sobre ti, como
          tarjetas de crédito o datos bancarios, fotografías, tu DNI o
          información personal, etc. Los datos que guardan son de carácter
          técnico, preferencias personales, personalización de contenidos, etc.
        </p>

        <p>
          El servidor web no te asocia como persona si no a tu navegador web. De
          hecho, si navegas habitualmente con Internet Explorer y pruebas a
          navegar por la misma web con Firefox o Chrome verás que la web no se
          da cuenta que eres la misma persona porque en realidad está asociando
          al navegador, no a la persona.
        </p>

        <h3>¿Qué ocurre si desactivo las cookies?</h3>

        <p>
          Para que entiendas el alcance que puede tener desactivar las cookies
          te mostramos unos ejemplos:
        </p>

        <ul>
          <li>
            No podrás compartir contenidos de esa web en Facebook, Twitter o
            cualquier otra red social.
          </li>
          <li>
            El sitio web no podrá adaptar los contenidos a tus preferencias
            personales, como suele ocurrir en las tiendas online.
          </li>
          <li>
            No podrás acceder al área personal de la web, como por ejemplo Mi
            cuenta, o Mi perfil o Mis pedidos.
          </li>
          <li>
            Tiendas online: Te será imposible realizar compras online, tendrán
            que ser telefónicas o visitando la tienda física si es que dispone
            de ella.
          </li>
          <li>
            No será posible personalizar tus preferencias geográficas como
            franja horaria, divisa o idioma.
          </li>
          <li>
            El sitio web no podrá realizar analíticas web sobre visitantes y
            tráfico en la web, lo que dificultará que la web sea competitiva.
          </li>
          <li>
            No podrás escribir en el blog, no podrás subir fotos, publicar
            comentarios, valorar o puntuar contenidos. La web tampoco podrá
            saber si eres un humano o una aplicación automatizada que publica
            spam.
          </li>
          <li>
            No se podrá mostrar publicidad sectorizada, lo que reducirá los
            ingresos publicitarios de la web.
          </li>
          <li>
            Todas las redes sociales usan cookies, si las desactivas no podrás
            utilizar ninguna red social.
          </li>
        </ul>

        <h3>¿Qué tipos de cookies hay?</h3>

        <p>
          1. Tipos de cookies según la entidad que gestione el equipo o dominio
          desde donde se envían las cookies y trate los datos que se obtengan,
          podemos distinguir:
        </p>

        <ul>
          <li>
            Cookies obligatorias: Son aquéllas que se envían al equipo terminal
            del usuario desde un equipo o dominio gestionado por el propio
            editor y desde el que se presta el servicio solicitado por el
            usuario.
          </li>
          <li>
            CCookies de terceros: Son aquéllas que se envían al equipo terminal
            del usuario desde un equipo o dominio que no es gestionado por el
            editor, sino por otra entidad que trata los datos obtenidos través
            de las cookies.
          </li>
        </ul>

        <p>
          En el caso de que las cookies sean instaladas desde un equipo o
          dominio gestionado por el propio editor pero la información que se
          recoja mediante éstas sea gestionada por un tercero, no pueden ser
          consideradas como cookies propias.
        </p>

        <h3>Cookie DART</h3>

        <ul>
          <li>
            Google, como proveedor asociado, utiliza cookies para publicar
            anuncios en Asoc Comparsa Tot A Orri.
          </li>
          <li>
            Puedes inhabilitar el uso de la cookie de DART del sistema de
            anuncios de Google accediendo al centro de privacidad de Google.
          </li>
        </ul>

        <p>
          Además, los navegadores pueden también permitir a los usuarios ver y
          borrar cookies individualmente. Dispones de más información sobre las
          Cookies en:
          <a href="https://es.wikipedia.org/wiki/Cookie">Wikipedia</a>
        </p>

        <h3>Web Beacons</h3>

        <p>
          Este sitio puede albergar también web beacons (también conocidos como
          web bugs). Los web beacons suelen ser pequeñas imágenes de un pixel
          por un pixel, visibles o invisibles colocados dentro del código fuente
          de las páginas web de un sitio. Los web beacons sirven y se utilizan
          de una forma similar a las cookies.
        </p>

        <p>
          Además, los web beacons suelen utilizarse para medir el tráfico de
          usuarios que visitan una página web y poder sacar un patrón de los
          usuarios de un sitio. Dispones de más información sobre los web
          beacons en: Wikipedia
        </p>

        <h3>Cookies de terceros</h3>

        <p>
          En algunos casos, compartimos información sobre los visitantes de este
          sitio de forma anónima o agregada con terceros como puedan ser
          anunciantes, patrocinadores o auditores con el único fin de mejorar
          nuestros servicios.
        </p>

        <p>
          Todas estas tareas de procesamiento serán reguladas según las normas
          legales y se respetarán todos sus derechos en materia de protección de
          datos conforme a la regulación vigente.
        </p>

        <p>
          Este sitio mide el tráfico con diferentes soluciones que pueden
          utilizar cookies o web beacons para analizar lo que sucede en nuestras
          páginas.
        </p>

        <p>
          Actualmente utilizamos las siguientes soluciones para la medición del
          tráfico de este sitio. Puedes ver más información sobre la política de
          privacidad de cada una de las soluciones utilizadas para tal efecto:
        </p>

        <ul>
          <li>
            <a href="http://www.google.com/intl/es/policies/privacy/partners/"
              >Cómo usa Google los datos que recopila en los sitios web</a
            >
          </li>
          <li>
            Google (Analytics):
            <a href="https://policies.google.com/?hl=es"
              >Política de Privacidad de Google Analytics</a
            >
            (si lo deseas puedes inhabilitar el seguimiento de Google Analytics
            mediante
            <a href="https://tools.google.com/dlpage/gaoptout"
              >este complemento para el navegador ofrecido por Google)</a
            >
          </li>
        </ul>

        <p>
          Este sitio también puede albergar publicidad propia, de afiliados, o
          de redes publicitarias. Esta publicidad se muestra mediante servidores
          publicitarios que también utilizan cookies para mostrar contenidos
          publicitarios afines a los usuarios.
        </p>

        <p>
          Cada uno de estos servidores publicitarios dispone de su propia
          política de privacidad, que puede ser consultada en sus propias
          páginas web. ¿Qué tipos de cookies se utilizan en este sitio web?
        </p>

        <p>
          Al acceder a la web Asoc Comparsa Tot A Orri te informamos que si
          sigues navegando se te instalarán diversas cookies propias y de
          terceros correspondientes a las analíticas de la web (a modo de
          ejemplo: Google Analytics o herramientas similares) para ayudar al
          sitio web a analizar el uso que haces del sitio web y mejorar la
          usabilidad del mismo, pero en ningún caso se asocian a datos que
          pudieran llegar a identificarte.
        </p>

        <p>
          Los datos que guardan son de carácter técnico, preferencias
          personales, personalización de contenidos, etc. El servidor web no te
          asocia como persona sino a tu navegador web.
        </p>

        <p>
          Esta web se utiliza cookies propias y de terceros para conseguir que
          tengas una mejor experiencia de navegación, puedas compartir contenido
          en redes sociales, dejar comentarios y para obtener estadísticas de
          nuestros usuarios.
        </p>

        <p>
          Como usuario, puedes rechazar el tratamiento de los datos o la
          información bloqueando estas cookies mediante la configuración
          apropiada de su navegador.
        </p>

        <p>
          Sin embargo, debes saber que si lo haces, este sitio puede no
          funcionar adecuadamente. Por eso es importante que leas la presente
          política de cookies y comprendas que si continúas navegando,
          consideramos que aceptas su uso.
        </p>

        <p>
          Puedes cambiar la configuración de tu navegador para ser avisado de la
          recepción de cookies en tu dispositivo o desactivar si lo deseas la
          capacidad de almacenar dichas cookies en tu dispositivo.
        </p>

        <h3>¿Cómo se gestionan las cookies?</h3>

        <p>
          Puesto que las cookies son archivos de texto normales, se pueden
          explorar con la mayoría de editores de texto o programas de
          procesamiento de texto. Puedes hacer clic en una cookie para abrirla.
        </p>

        <p>
          A continuación, se indica una lista de enlaces sobre cómo ver cookies
          en diferentes navegadores. Si utilizas otro navegador, consulte la
          información sobre cookies en el propio navegador. Si utilizas un
          teléfono móvil, consulta el manual del dispositivo para obtener más
          información.
        </p>

        <ul>
          <li>
            <strong>Firefox:</strong>
            <a
              href="https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer"
              >https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer</a
            >
          </li>
          <li>
            <strong>Chrome:</strong>
            <a
              href="https://support.google.com/chrome/bin/answer.py?hl=en&amp;answer=95647&amp;topic=14666&amp;ctx=topic"
              >https://support.google.com/chrome/bin/answer.py?hl=en&amp;answer=95647&amp;topic=14666&amp;ctx=topic</a
            >
          </li>
          <li>
            <strong>Internet Explorer 8-10:</strong>
            <a
              href="http://windows.microsoft.com/en-US/internet-explorer/delete-manage-cookies"
              >http://windows.microsoft.com/en-US/internet-explorer/delete-manage-cookies
            </a>
          </li>
          <li>
            <strong>Safari:</strong>
            <a href="http://support.apple.com/kb/ph5042">
              http://support.apple.com/kb/ph5042</a
            >
          </li>
          <li>
            <strong>Opera:</strong>
            <a href="http://help.opera.com/Windows/12.00/es-ES/cookies.html">
              http://help.opera.com/Windows/12.00/es-ES/cookies.html</a
            >
          </li>
        </ul>

        <h3>Configuración de cookies para los navegadores más populares</h3>

        <p>
          A continuación te indicamos cómo acceder a una cookie determinada del
          navegador <strong>Chrome</strong>. Nota: estos pasos pueden variar en
          función de la versión del navegador:
        </p>

        <ol>
          <li>
            Ve a Configuración o Preferencias mediante el menú Archivo o bien
            pinchando el icono de personalización que aparece arriba a la
            derecha.
          </li>
          <li>
            Verás diferentes secciones, pincha la opción Mostrar opciones
            avanzadas.
          </li>
          <li>Ve a Privacidad, Configuración de contenido.</li>
          <li>Selecciona Todas las cookies y los datos de sitios.</li>
          <li>
            Aparecerá un listado con todas las cookies ordenadas por dominio.
            Para que te sea más fácil encontrar las cookies de un determinado
            dominio introduzca parcial o totalmente la dirección en el campo
            Buscar cookies.
          </li>
          <li>
            Tras realizar este filtro aparecerán en pantalla una o varias líneas
            con las cookies de la web solicitada. Ahora sólo tienes que
            seleccionarla y pulsar la X para proceder a su eliminación.
          </li>
        </ol>

        <p>
          Para acceder a la configuración de cookies del navegador
          <strong>Internet Explorer</strong> sigue estos pasos (pueden variar en
          función de la versión del navegador):
        </p>

        <ol>
          Ve a Herramientas, Opciones de Internet Haz clic en Privacidad. Mueve
          el deslizador hasta ajustar el nivel de privacidad que desees.
        </ol>

        <p>
          Para acceder a la configuración de cookies del navegador
          <strong>Firefox</strong> sigue estos pasos (pueden variar en función
          de la versión del navegador):
        </p>

        <ol>
          Ve a Opciones o Preferencias según tu sistema operativo. Haz clic en
          Privacidad. En Historial elige Usar una configuración personalizada
          para el historial. Ahora verás la opción Aceptar cookies, puedes
          activarla o desactivarla según tus preferencias.
        </ol>

        <p>
          Para acceder a la configuración de cookies del navegador
          <strong>Safari para OSX</strong> sigue estos pasos (pueden variar en
          función de la versión del navegador):
        </p>

        <ol>
          <li>Ve a Preferencias, luego Privacidad.</li>
          <li>
            En este lugar verás la opción Bloquear cookies para que ajuste el
            tipo de bloqueo que deseas realizar.
          </li>
        </ol>

        <p>
          Para acceder a la configuración de cookies del navegador
          <strong>Safari para iOS</strong> sigue estos pasos (pueden variar en
          función de la versión del navegador):
        </p>

        <ol>
          <li>Ve a Ajustes, luego Safari.</li>
          <li>
            Ve a Privacidad y Seguridad, verás la opción Bloquear cookies para
            que ajuste el tipo de bloqueo que deseas realizar.
          </li>
        </ol>

        <p>
          Para acceder a la configuración de cookies del navegador para
          dispositivos <strong>Android</strong> sigue estos pasos (pueden variar
          en función de la versión del navegador):
        </p>

        <ol>
          <li>Ejecuta el navegador y pulsa la tecla Menú, luego Ajustes.</li>
          <li>
            Ve a Seguridad y Privacidad, verás la opción Aceptar cookies para
            que active o desactive la casilla.
          </li>
        </ol>

        <p>
          Para acceder a la configuración de cookies del navegador para
          dispositivos <strong>Windows Phone</strong> sigue estos pasos (pueden
          variar en función de la versión del navegador):
        </p>

        <ol>
          <li>Abre Internet Explorer, luego Más, luego Configuración</li>
          <li>
            Ahora puedes activar o desactivar la casilla Permitir cookies.
          </li>
        </ol>

        <h3>Notas adicionales</h3>

        <ul>
          <li>
            Ni esta web ni sus representantes legales se hacen responsables ni
            del contenido ni de la veracidad de las políticas de privacidad que
            puedan tener los terceros mencionados en esta política de cookies.
          </li>
          <li>
            Los navegadores web son las herramientas encargadas de almacenar las
            cookies y desde este lugar debe efectuar su derecho a eliminación o
            desactivación de las mismas. Ni esta web ni sus representantes
            legales pueden garantizar la correcta o incorrecta manipulación de
            las cookies por parte de los mencionados navegadores.
          </li>
          <li>
            En algunos casos es necesario instalar cookies para que el navegador
            no olvide tu decisión de no aceptación de las mismas.
          </li>
          <li>
            En el caso de las cookies de Google Analytics, esta empresa almacena
            las cookies en servidores ubicados en Estados Unidos y se compromete
            a no compartirla con terceros, excepto en los casos en los que sea
            necesario para el funcionamiento del sistema o cuando la ley obligue
            a tal efecto. Según Google no guarda su dirección IP. Google Inc. es
            una compañía adherida al Acuerdo de Puerto Seguro que garantiza que
            todos los datos transferidos serán tratados con un nivel de
            protección acorde a la normativa europea.
          </li>
          <li>
            Para cualquier duda o consulta acerca de esta política de cookies no
            dudes en comunicarte con nosotros a través de la sección de
            contacto.
          </li>
        </ul>

        <h2>Responsabilidad legal por el contenido</h2>

        <p>
          Asoc Comparsa Tot A Orri contiene textos elaborados con fines
          meramente informativos o divulgativos que pueden no reflejar el estado
          actual de la legislación o la jurisprudencia y que se refieren a
          situaciones generales por lo que su contenido no puede ser aplicado
          nunca por el usuario a casos concretos.
        </p>

        <p>
          Las opiniones vertidas en los mismos no reflejan necesariamente los
          puntos de vista de Comparsa Tot A Orri.
        </p>

        <p>
          El contenido de los artículos publicados en Asoc Comparsa Tot A Orri
          no puede ser considerado, en ningún caso, sustitutivo de asesoramiento
          legal.
        </p>

        <p>
          El usuario no debe actuar sobre la base de la información contenida en
          Asoc Comparsa Tot A Orri sin recurrir previamente al correspondiente
          asesoramiento profesional.
        </p>

        <h3>Derechos de propiedad intelectual e industrial</h3>

        <p>
          Mediante estas Condiciones Generales no se cede ningún derecho de
          propiedad intelectual o industrial sobre el portal Asoc Comparsa Tot A
          Orri ni sobre ninguno de sus elementos integrantes, quedando
          expresamente prohibidos al Usuario la reproducción, transformación,
          distribución, comunicación pública, puesta a disposición del público,
          extracción, reutilización, reenvío o la utilización de cualquier
          naturaleza, por cualquier medio o procedimiento, de cualquiera de
          ellos, salvo en los casos en que esté legalmente permitido o sea
          autorizado por el titular de los correspondientes derechos.
        </p>

        <p>
          El usuario conoce y acepta que la totalidad del sitio web, conteniendo
          sin carácter exhaustivo el texto, las imágenes, los diseños, software,
          contenidos (incluyendo estructura, selección, ordenación y
          presentación de los mismos), material audiovisual y gráficos, está
          protegida por marcas, derechos de autor y otros derechos legítimos
          registrados, de acuerdo con los tratados internacionales en los que
          España es parte y otros derechos de propiedad y leyes de España.
        </p>

        <p>
          En el caso de que un usuario o un tercero consideren que se ha
          producido una violación de sus legítimos derechos de propiedad
          intelectual por la introducción de un determinado contenido en Asoc
          Comparsa Tot A Orri, deberá notificar dicha circunstancia a Comparsa
          Tot A Orri indicando:
        </p>

        <ul>
          <li>
            Datos personales del interesado titular de los derechos
            presuntamente infringidos, o indicar la representación con la que
            actúa en caso de que la reclamación la presente un tercero distinto
            del interesado.
          </li>
        </ul>

        <p>
          Señalar los contenidos protegidos por los derechos de propiedad
          intelectual y su ubicación en Asoc Comparsa Tot A Orri, la
          acreditación de los derechos de propiedad intelectual señalados y
          declaración expresa en la que el interesado se responsabiliza de la
          veracidad de las informaciones facilitadas en la notificación.
        </p>

        <h3>
          Normativa y resolución de conflictos
        </h3>

        <p>
          Las presentes condiciones de uso de Asoc Comparsa Tot A Orri se rigen
          en todos y cada uno de sus extremos por la ley española. El idioma de
          redacción e interpretación de este aviso legal es el español.
        </p>

        <p>
          Este aviso legal no se archivará individualmente para cada usuario
          sino que permanecerá accesible por medio de Internet en Asoc Comparsa
          Tot A Orri.
        </p>

        <p>
          Los usuarios pueden someterse al Sistema Arbitral de Consumo del que
          Comparsa Tot A Orri formará parte para resolver cualquier controversia
          o reclamación derivada del presente texto o de cualquier actividad de
          Comparsa Tot A Orri, excepto para solventar aquellos conflictos que
          traigan causa del desarrollo de una actividad que requiera
          colegiación, en cuyo caso el usuario deberá dirigirse al órgano
          correspondiente del colegio de abogados oportuno.
        </p>

        <p>
          Los usuarios que tengan la condición de consumidores o usuarios
          conforme los define la normativa española y residan en la Unión
          Europea, si han tenido un problema con una compra online realizada a
          Comparsa Tot A Orri, para tratar de llegar a un acuerdo extrajudicial
          pueden acudir a la Plataforma de Resolución de Litigios en Línea,
          creada por la Unión Europea y desarrollada por la Comisión Europea al
          amparo del Reglamento (UE) 524/2013.
        </p>

        <p>
          Siempre que el usuario no sea consumidor o usuario, y cuando no haya
          una norma que obligue a otra cosa, las partes acuerdan someterse a los
          Juzgados y Tribunales de capital, por ser este el lugar de celebración
          del contrato, con renuncia expresa a cualquier otra jurisdicción que
          pudiera corresponderles.
        </p>

        <h2>Qué esperamos de los usuarios</h2>

        <p>
          El acceso y/o uso de Asoc Comparsa Tot A Orri a quien lo realiza la
          condición de Usuario, aceptando, desde este mismo momento, plenamente
          y sin reserva alguna, el presente aviso legal, así como las
          condiciones particulares que, en su caso, lo complementen, en relación
          con determinados servicios y contenidos de Asoc Comparsa Tot A Orri.
        </p>

        <p>
          El usuario queda informado, y acepta, que el acceso a Asoc Comparsa
          Tot A Orri no supone, en modo alguno, el inicio de una relación
          comercial con Comparsa Tot A Orri. De esta forma, el usuario se
          compromete a utilizar el sitio Web, sus servicios y contenidos sin
          contravenir la legislación vigente, la buena fe y el orden público.
        </p>

        <p>
          Queda prohibido el uso de la web con fines ilícitos o lesivos, o que,
          de cualquier forma, puedan causar perjuicio o impedir el normal
          funcionamiento del sitio web. Respecto de los contenidos de este sitio
          web, se prohíbe:
        </p>

        <ul>
          <li>
            Su reproducción, distribución o modificación, total o parcial, a
            menos que se cuente con la autorización de sus legítimos titulares.
          </li>
          <li>
            Cualquier vulneración de los derechos del prestador o de los
            legítimos titulares.
          </li>
          <li>Su utilización para fines comerciales o publicitarios.</li>
        </ul>

        <h3>Enlaces Externos</h3>

        <p>
          Las páginas del sitio web Asoc Comparsa Tot A Orri proporcionan
          enlaces a otros sitios web propios y contenidos que son propiedad de
          terceros la de fabricantes o proveedores.
        </p>

        <p>
          El único objeto de los enlaces es proporcionar al Usuario la
          posibilidad de acceder a dichos enlaces y conocer nuestros productos,
          aunque Comparsa Tot A Orri no se responsabiliza en ningún caso de los
          resultados que puedan derivarse al usuario por acceso a dichos
          enlaces.
        </p>

        <p>
          El usuario que se proponga establecer cualquier dispositivo técnico de
          enlace desde su sitio web al portal Asoc Comparsa Tot A Orri deberá
          obtener la autorización previa y escrita de Comparsa Tot A Orri.
        </p>

        <p>
          El establecimiento del enlace no implica en ningún caso la existencia
          de relaciones entre Comparsa Tot A Orri y el propietario del sitio en
          el que se establezca el enlace, ni la aceptación o aprobación por
          parte de Comparsa Tot A Orri de sus contenidos o servicios.
        </p>

        <h3>Remarketing</h3>

        <p>
          La función de
          <a href="https://support.google.com/adwords/answer/1752338"
            >remarketing</a
          >
          o de audiencias similares de AdWords nos permiten llegar a las
          personas que hayan visitado nuestro sitio web anteriormente y
          ayudarlos a completar su proceso de venta.
        </p>

        <p>
          Como usuario, cuando entres en nuestra web, te instalaremos una cookie
          de remarketing (puede ser de Google Adwords, de Criteo o de otros
          servicios que ofrezcan remarketing).
        </p>

        <ul>
          <li>
            Esa cookie almacena información del visitante, como por ejemplo los
            productos que ha visitado o si ha abandonado el carrito de la
            compra.
          </li>
          <li>
            Cuando el visitante abandona nuestra web, la cookie de remarketing
            sigue en su navegador.
          </li>
        </ul>

        <h3>Otras condiciones de uso de este sito web</h3>

        <p>
          El usuario se compromete a hacer un uso diligente del sitio web y de
          los servicios accesibles desde el mismo, con total sujeción a la Ley,
          a las buenas costumbres y al presente aviso legal.
        </p>

        <p>
          Asimismo, se compromete, salvo autorización previa, expresa y escrita
          de Comparsa Tot A Orri a utilizar la información contenida en el sitio
          web, exclusivamente para su información, no pudiendo realizar ni
          directa ni indirectamente una explotación comercial de los contenidos
          a los que tiene acceso.
        </p>

        <p>
          Este sitio almacena un fichero de datos relativos a los comentarios
          enviados a este sitio.
        </p>

        <p>
          Podrá ejercitar sus derechos de acceso, rectificación, cancelación u
          oposición mediante un envío de un correo electrónico a la dirección
          Tot a Orri.
        </p>

        <p>
          Este sitio, los dominios asociados y la propiedad del contenido
          pertenecen a , CIF G12661658, con domicilio en BISBE LASSALA, Nº 41
          12500, VINAROS, CASTELLON
        </p>

        <p>
          Este sitio web contiene hiperenlaces que conducen a otras páginas web
          gestionadas por terceros ajenos a nuestra organización. Comparsa Tot A
          Orri no garantiza ni se hace responsable del contenido que se recoja
          en dichas paginas web.
        </p>

        <p>
          Salvo autorización expresa, previa y por escrito de Comparsa Tot A
          Orri, queda terminantemente prohibida la reproducción, excepto para
          uso privado, la transformación, y en general cualquier otra forma de
          explotación, por cualquier procedimiento, de todo o parte de los
          contenidos de este sitio web.
        </p>

        <p>
          Queda terminantemente prohibido realizar, sin el previo consentimiento
          de Comparsa Tot A Orri cualquier manipulación o alteración de este
          sitio web.
        </p>

        <p>
          Consecuentemente, Comparsa Tot A Orri asumirá ninguna responsabilidad
          derivada, o que pudiera derivarse, de dicha alteración o manipulación
          por terceros.
        </p>

        <h3>Ejercicio de derechos ARCO</h3>

        <p>
          Podrás ejercitar, respecto de los datos recabados, los derechos
          reconocidos en la Ley Orgánica 15/1999, de acceso, rectificación o
          cancelación de datos y oposición.
        </p>

        <p>
          Por ello te informo que podrás ejercer dichos derechos mediante
          solicitud escrita y firmada que podrá enviar, junto con fotocopia de
          tu DNI o documento identificativos equivalente, al domicilio postal de
          Asoc Comparsa Tot A Orri, CIF G12661658, en BISBE LASSALA, Nº 41
          12500, VINAROS, CASTELLON , España o por correo electrónico, adjuntado
          fotocopia de DNI a:
        </p>

        <p>
          Antes de los 10 días responderemos a tu solicitud para confirmarte la
          ejecución del derecho que hayas solicitado ejercer. Exclusión de
          garantías y responsabilidad
        </p>

        <p>
          Comparsa Tot A Orri no otorga ninguna garantía ni se hace responsable,
          en ningún caso, de los daños y perjuicios de cualquier naturaleza que
          pudieran traer causa de:
        </p>

        <ul>
          <li>
            La falta de disponibilidad, mantenimiento y efectivo funcionamiento
            de la web o de sus servicios y contenidos;
          </li>
          <li>
            La existencia de virus, programas maliciosos o lesivos en los
            contenidos;
          </li>
          <li>
            El uso ilícito, negligente, fraudulento o contrario a este Aviso
            Legal;
          </li>
          <li>
            La falta de licitud, calidad, fiabilidad, utilidad y disponibilidad
            de los servicios prestados por terceros y puestos a disposición de
            los usuarios en el sitio web.
          </li>
        </ul>

        Comparsa Tot A Orri no se hace responsable bajo ningún concepto de los
        daños que pudieran dimanar del uso ilegal o indebido del presente sitio
        web.

        <h3>Plataforma Europea de resolución de litigios en línea</h3>

        <p>
          La Comisión Europea facilita una plataforma de resolución de litigios
          en línea que se encuentra disponible en el siguiente enlace:
          <a href="http://ec.europa.eu/consumers/odr/"></a>. Los consumidores
          podrán someter sus reclamaciones a través de la plataforma de
          resolución de litigios en línea.
        </p>

        <h3>Ley aplicable y jurisdicción</h3>

        <p>
          Con carácter general las relaciones entre Comparsa Tot A Orri con los
          usuarios de sus servicios telemáticos, presentes en este sitio web, se
          encuentran sometidas a la legislación y jurisdicción españolas.
        </p>

        <h3>Siempre estaremos localizables: Nuestro contacto</h3>

        <p>
          En caso de que cualquier usuario tuviese alguna duda acerca de estas
          condiciones legales o cualquier comentario sobre el portal Asoc
          Comparsa Tot A Orri, por favor dirígete a Tot a Orri o utiliza el
          formulario (en caso de existir) o datos de contacto disponibles en el
          sitio web.
        </p>

        <p>
          Nuestra política de privacidad describe como recogemos, guardamos o
          utilizamos la información que recabamos a través de los diferentes
          servicios o páginas disponibles en este sitio.
        </p>

        <p>
          Es importante que entiendas qué información recogemos y cémo la
          utilizamos ya que el acceso a este sitio implica la aceptación nuestra
          política de privacidad.
        </p>

        <h3>Aceptación y consentimiento</h3>

        <p>
          El usuario declara haber sido informado de las condiciones sobre
          protección de datos de carácter personal, aceptando y consintiendo el
          tratamiento de los mismos por parte de Comparsa Tot A Orri, en la
          forma y para las finalidades indicadas en la presente política de
          privacidad.
        </p>

        <h3>Correos comerciales</h3>

        <p>
          De acuerdo con la legislación vigente, Comparsa Tot A Orri no realiza
          prácticas de SPAM, por lo que no envía correos comerciales por vía
          electrónica que no hayan sido previamente solicitados o autorizados
          por el usuario.
        </p>

        <p></p>
        En consecuencia, en cada uno de los formularios habidos en Asoc Comparsa
        Tot A Orri, el Usuario tiene la posibilidad de dar su consentimiento
        expreso para recibir nuestra Newsletter/boletín, con independencia de la
        información comercial puntualmente solicitada.

        <!-- Texto de política de privacidad ///////////////////////////////////// -->
        <!-- Texto de política de privacidad ///////////////////////////////////// -->
        <!-- Texto de política de privacidad ///////////////////////////////////// -->
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import Title from "@/components/layout/Title.vue";
import { useStore } from "@/store";
export default defineComponent({
  name: "Privacidad",
  components: {
    Title,
  },
  setup() {
    const store = useStore();

    return {
      entityName: store.state.entityName,
      entityType: store.state.entityType,
    };
  },
});
</script>
<style lang=""></style>
