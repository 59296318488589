
import { computed, defineComponent, onBeforeMount, PropType, ref, toRaw } from "vue";
import Title from "@/components/layout/Title.vue";
import Modal from "@/components/ui/Modal.vue";
import TiendaProductoAddSocio from "@/views/tienda/components/TiendaProductoAddSocio.vue";
import CreditCard from "@/views/perfil/components/CreditCard.vue";
import { useStore } from "@/store";
import { Producto } from "@/models/tienda/Producto";
import { User } from "@/store/modules/user/interfaces";

export default defineComponent({
  name: "Tienda",
  components: {
    Title,
    Modal,
    TiendaProductoAddSocio,
    CreditCard,
  },
  props: {
    producto: {
      type: Object as PropType<Producto>,
      default: {},
    }
  },
  setup({ producto }) {
    const store = useStore();
    const assetsUrl = store.state.assetsUrl;

    const isLoading = ref(true);
    const procesandoPago = ref(false);
    const fpagSocio = ref({} as any);
    const importe = ref(0);
    let socios = [] as any[];

    onBeforeMount(async () => {
      // Obtener familiares
      await store.dispatch("user/getFamilia");
      isLoading.value = false;
    })

    // Actualizar importe a pagar
    const updateImporte = (payload: { socio: User, importe: number, enabled: boolean }) => {
      // Añadir o quitar socio
      if (payload.enabled) {
        socios.push({
          id: payload.socio.id,
          tarifa: {
            id: payload.socio.tipo_socio.id,
            descripcion: payload.socio.tipo_socio.descripcion
          },
          importe: payload.importe
        })
      } else {
        socios = socios.filter(socio => socio.id !== payload.socio.id);
      }

      // Sumar o restar importe
      importe.value += payload.enabled ? payload.importe : -payload.importe;
    }

    // Iniciar pago
    const startPay = async () => {
      procesandoPago.value = true;

      const payload = {
        producto: producto.id,
        socios,
        total: toRaw(importe.value),
        fpag: toRaw(fpagSocio.value.datosTarjeta)
      }

      // Enviar peticion de pago
      const res = await store.dispatch('user/pagarProducto', payload);

      console.log('respuesta proceso de pago tienda', res);

      // Redireccionar si es mediante pasarela de pagos.
      // Creamos un form oculto para hacer la peticion
      const form = document.createElement('form');
      form.method = 'post';
      form.action = res.request.url;

      const params = {
        "Ds_Signature": res.request.ds_signature,
        "Ds_SignatureVersion": res.request.ds_signature_version,
        "Ds_MerchantParameters": res.request.ds_merchant_parameters
      };

      for (const [key, value] of Object.entries(params)) {
        const hiddenField = document.createElement('input');
        hiddenField.type = 'hidden';
        hiddenField.name = key;
        hiddenField.value = value;

        form.appendChild(hiddenField);
      }

      // Redireccion en misma ventana, ya que en otra distinta genera problemas de bloqueo popup
      window.document.body.appendChild(form);
      form.submit();

      console.log('Transaccion ID', res.transaccion_id);
    }

    return {
      assetsUrl,
      entityName: store.state.entityName,
      entityType: store.state.entityType,
      socio: computed(() => store.state.user.perfil),
      familia: computed(() => store.state.user.familia),
      isLoading,
      procesandoPago,
      fpagSocio,
      importe,
      updateImporte,
      startPay
    };
  },
});
