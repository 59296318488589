
import { defineComponent, ref } from "vue";
import axiosApi from "@/services/axios";
import { useStore } from "@/store";

import { User } from "@/store/modules/user/interfaces";

export default defineComponent({
  name: "PerfilAddFamiliar",
  components: {},
  setup() {
    const store = useStore();

    const dni = ref('');
    const fechaNacimiento = ref('');
    const resultado = ref([] as User[]);
    const selected = ref(false);

    const searchFamiliar = async () => {
      const familiar = await axiosApi.post('/familia/search', {
        dni: dni.value,
        fechaNacimiento: fechaNacimiento.value
      }).then(res => res.data);

      if (familiar.length > 0) {
        selected.value = true;
      }

      resultado.value = familiar;
    }

    const guardarFamiliar = async () => {
      if (selected.value) {
        console.log('Añadiendo familiar', resultado.value[0]);
        const res = await axiosApi.post('/familia/add', {
          dni: dni.value,
          fechaNacimiento: fechaNacimiento.value
        }).then(r => r.data);
        
        if (res.ok) {
          store.dispatch('user/getFamilia');
        }
      }

      dni.value = '';
      fechaNacimiento.value = '';
      resultado.value = [];
      selected.value = false;
    }

    return {
      dni,
      fechaNacimiento,
      resultado,
      selected,
      searchFamiliar,
      guardarFamiliar
    }
  }
});
