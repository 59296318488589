
import { computed, defineComponent, PropType, ref } from "vue";
import { User } from "@/store/modules/user/interfaces";
import { formatDistanceToNow } from "date-fns";
import { es } from "date-fns/locale";

import PerfilFichaEdit from "@/views/perfil/components/PerfilFichaEdit.vue";
import Modal from "@/components/ui/Modal.vue";

export default defineComponent({
  name: "PerfilFicha",
  components: {
    PerfilFichaEdit,
    Modal,
  },
  props: {
    perfil: {
      type: Object as PropType<User>,
      default: {},
    },
    isFamiliar: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const isFamiliar = computed(() => props.isFamiliar);
    const modalEditPerfil = ref();
    const perfil = computed(() => props.perfil);
    const edad = computed(() => {
      if (!props.perfil.fecha_naci) {
        return "...";
      }
      return formatDistanceToNow(new Date(props.perfil.fecha_naci), {
        locale: es,
      });
    });

    return {
      isFamiliar,
      perfil,
      edad,
      modalEditPerfil
    };
  },
});
