<template>
  <CreditCard></CreditCard>

  <div class="mb-3">
    <h2 class="head-3">
      ¿Para que sirve?
    </h2>

    <p>
      Si guardas la tarjeta en tu perfil no tendrás que escribirla en cada
      compra.
      <strong>Asociación {{ entityType }} {{ entityName }}</strong> no cobrara
      nada de esta tarjeta sin tu consentimiento.
    </p>

    <p>
      Si has añadido a tu familia a este usuario, con esta tarjeta también
      podrás pagar sus compras o cuotas de socio.
    </p>
  </div>

  <div class="mb-5">
    <h2 class="head-3">
      Seguridad
    </h2>
    <ul>
      <li>
        <strong>Asociación {{ entityType }} {{ entityName }}</strong> almacena
        los datos encriptados y de forma segura.
      </li>
      <li>
        Usamos una pasarela de pago segura de Caixa Rural Vinaròs
      </li>
    </ul>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { useStore } from "@/store";
import CreditCard from "@/views/perfil/components/CreditCard.vue";

export default defineComponent({
  name: "PerfilPagosEdit",
  components: {
    CreditCard,
  },
  setup() {
    const store = useStore();

    return {
      entityName: store.state.entityName,
      entityType: store.state.entityType,
    };
  },
});
</script>
<style lang="scss"></style>
