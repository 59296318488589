
import { defineComponent, reactive } from "vue";

export default defineComponent({
  name: "CreditCard",
  props: {
    light: {
      type: Boolean,
      default: false
    },
  },
  setup(props) {
    const { light } = props;

    const datosTarjeta = reactive({
      titular: '',
      save: true
    });

    return {
      light,
      datosTarjeta
    }
  }
});
