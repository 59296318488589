<template>
  <!-- Button trigger modal -->

  <!-- Modal -->
  <div
    id="myModal"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-fullscreen-md-down">
      <div class="modal-content">
        <div class="modal-header">
          <slot name="titulo"></slot>
          <button
            ref="closeModalButton"
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <slot></slot>
        </div>
        <div class="modal-footer">
          <slot name="footer"></slot>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, onBeforeUnmount, onMounted, onUnmounted, ref } from "vue";
import * as bootstrap from 'bootstrap';

export default defineComponent({
  name: "Modal",
  setup() {
    const closeModalButton = ref();

    const closeModal = () => {
      closeModalButton.value.click();
    }

    onBeforeUnmount(() => {
      // Cerrar modal si estubiese abierto
      closeModal();
    })

    return {
      closeModalButton,
      closeModal
    }
  }
});
</script>
<style lang="scss"></style>
