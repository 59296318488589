<template lang="html">
  <div class="mb-3">
    <div class="mb-3">
      <label
        for="labelFechanacimiento"
        class="form-label"
      >{{ light ? 'Nombre y apellidos del titular de la tarjeta' : 'Nombre del titular' }}</label>
      <input
        type="text"
        class="form-control"
        id="labelFechanacimiento"
        placeholder="Nombre"
        v-model="datosTarjeta.titular"
      />
      <small
        v-if="datosTarjeta.titular.trim() === ''"
        class="text-danger mb-3"
      >Este campo es obligatorio</small>

      <template v-if="!light">
        <label for="labelFechanacimiento" class="form-label">Primer Apellido</label>
        <input
          type="text"
          class="form-control mb-3"
          id="labelFechanacimiento"
          placeholder="Primer Apellido"
        />

        <label for="labelFechanacimiento" class="form-label">Segundo Apellido</label>
        <input
          type="text"
          class="form-control mb-3"
          id="labelFechanacimiento"
          placeholder="Segundo Apellido"
        />
      </template>
    </div>

    <template v-if="!light">
      <div class="mb-3">
        <label for="labelCardNombre" class="form-label">Numero de tarjeta</label>
        <div class="input-group">
          <input
            type="text"
            class="form-control"
            id="labelCardNombre"
            placeholder="0000 - 0000 - 0000 - 0000"
            v-maska="'#### - #### - #### - ####'"
          />
          <span class="input-group-text" id="basic-addon2">
            <i class="bi bi-credit-card-2-front"></i>
          </span>
        </div>
        <div class="form-text">
          Los datos se encriptan en tu cuenta de forma segura y solo los podrás
          ver y modificar tu.
        </div>
      </div>

      <div class="row">
        <div class="col-4 col-md-4">
          <div class="mb-3">
            <label for="labelCaducidad" class="form-label">Caduca mes</label>
            <input type="text" class="form-control" id="labelCaducidad" placeholder="00" />
          </div>
        </div>
        <div class="col-4 col-md-4">
          <div class="mb-3">
            <label for="labelYear" class="form-label">Caduca año</label>
            <input type="text" class="form-control" id="labelYear" placeholder="00" />
          </div>
        </div>
        <div class="col-4 col-md-4">
          <div class="mb-3">
            <label for="labelCvv" class="form-label">CVV</label>
            <div class="input-group">
              <input type="text" class="form-control" id="labelCvv" placeholder="000" />
              <span class="input-group-text" id="basic-addon2">
                <i class="bi bi-credit-card"></i>
              </span>
            </div>
          </div>
        </div>
      </div>
    </template>

    <div class="mb-3">
      <div class="form-check form-switch">
        <input
          class="form-check-input"
          type="checkbox"
          id="SaveCheckedLabel"
          v-model="datosTarjeta.save"
        />
        <label class="form-check-label fw-bold" for="SaveCheckedLabel">Guardar tarjeta</label>
        <div class="form-text">
          Guarda tu tarjeta, para pagar comodamente las compras de la comparsa.
          <br />
          <br />* No guardamos los datos completos de tu tarjeta, solo un identificador proporcionado por la pasarela de pagos.
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, reactive } from "vue";

export default defineComponent({
  name: "CreditCard",
  props: {
    light: {
      type: Boolean,
      default: false
    },
  },
  setup(props) {
    const { light } = props;

    const datosTarjeta = reactive({
      titular: '',
      save: true
    });

    return {
      light,
      datosTarjeta
    }
  }
});
</script>
<style lang=""></style>
