import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { initialState, State, store } from "@/store";

import Home from "../views/home/Home.vue";
import Login from "../views/login/Login.vue";
import LoginReset from "../views/login/LoginReset.vue";
import LoginResetPassword from "../views/login/LoginResetPassword.vue";
import LoginResetSuccess from "../views/login/LoginResetSuccess.vue";
import OnboardingStep1 from "../views/onboarding/OnboardingStep1.vue";
import OnboardingStep2 from "../views/onboarding/OnboardingStep2.vue";
import Perfil from "../views/perfil/Perfil.vue";
import PerfilFamiliar from "../views/perfil/PerfilFamiliar.vue";
import Tienda from "../views/tienda/Tienda.vue";
import Tickets from "../views/tickets/Tickets.vue";
import Cuotas from "../views/cuotas/Cuotas.vue";
import PagoCorrecto from "../views/tienda/PagoCorrecto.vue";
import Privacidad from "../views/politicas/Privacidad.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    component: Home,
    meta: { title: "Tot a Orri" },
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      public: true,
      title: "Login - Tot a Orri",
    },
  },
  {
    path: "/login/reset",
    name: "LoginReset",
    component: LoginReset,
    meta: {
      public: true,
    },
  },
  {
    path: "/login/new-password/:token",
    name: "LoginResetPassword",
    component: LoginResetPassword,
    meta: {
      public: true,
    },
  },
  {
    path: "/login/success",
    name: "LoginResetSuccess",
    component: LoginResetSuccess,
    meta: {
      public: true,
    },
  },
  {
    path: "/onboarding/step-1",
    name: "Revisa tus datos",
    component: OnboardingStep1,
  },
  // {
  //   path: "/onboarding/step-2",
  //   name: "Forma de Pago",
  //   component: OnboardingStep2,
  // },
  {
    path: "/perfil",
    name: "Perfil de Socio",
    component: Perfil,
    meta: { title: "Perfil - Tot a Orri" },
  },
  {
    path: "/perfil/familia",
    name: "Gestión Familiar",
    component: PerfilFamiliar,
    meta: { title: "Familia - Tot a Orri" },
  },
  {
    path: "/socio",
    name: "Cuota de socio",
    component: Cuotas,
    meta: { title: "Socio - Tot a Orri" },
  },
  {
    path: "/tienda",
    name: "Tienda",
    component: Tienda,
    meta: { title: "Tienda - Tot a Orri" },
  },
  {
    path: "/tickets",
    name: "Tickets",
    component: Tickets,
    meta: { title: "Tickets - Tot a Orri" },
  },
  {
    path: "/redsys-respuesta/ok",
    name: "Pago Correcto",
    component: PagoCorrecto,
  },
  {
    path: "/redsys-respuesta/ko",
    name: "Pago Incorrecto",
    component: PagoCorrecto,
  },
  {
    path: "/politica-privacidad",
    name: "Política de Privacidad",
    component: Privacidad,
    meta: {
      public: true,
      title: "Política de privacidad",
    },
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/:catchAll(.*)",
    redirect: "/",
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from) => {
  window.scrollTo(0, 0);
  // Si no esta autenticado y quiere ir a una pagina privada, redirigir al login
  if (!to.meta.public && !store.getters.isAuthenticated) {
    return "/login";
  }

  // Si esta autenticado y quiere ir al login, no permitir, redirigir a la pagina anterior
  if (to.path.startsWith("/login") && store.getters.isAuthenticated) {
    return from;
  }

  // Comprobar si no ha completado el onboarding
  if (!to.path.startsWith("/onboarding") && store.getters.isAuthenticated) {
    if (store.state.user.isLoading) {
      console.log("Cargando perfil");
      return new Promise((resolve, reject) => {
        store.subscribeAction({
          after: (action, state) => {
            if (state.user.perfil?.onboarding_ok) {
              resolve(to);
            }

            resolve("/onboarding/step-1");
          },
        });
      });
    } else {
      if (!store.state.user.perfil?.onboarding_ok) {
        return "/onboarding/step-1";
      }
    }
  }
});

export default router;
