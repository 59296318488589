<template>
  <div class="producto" v-if="!isLoading">
    <article class="card card--card">
      <a
        href="#"
        class="producto__thumbnail"
        data-bs-toggle="modal"
        :data-bs-target="`#modal-${producto.id}`"
      >
        <img class="producto__img" :src="assetsUrl+producto.imagen" alt="" />
      </a>
      <div class="producto__content">
        <h2 class="producto__titulo">
          {{ producto.nombre }}
        </h2>
        <div class="producto__hasta">
          <label>Disponible hasta</label>
          <time class="badge badge--gris ms-2">
            {{ producto.fecha_fin }}
          </time>
        </div>
        <div class="producto__fecha" v-if="producto.fecha_evento">
          <label>Fecha del evento</label>
          <time class="badge badge--gris ms-2">
            {{ producto.fecha_evento }}
          </time>
        </div>
      </div>
    </article>

    <!-- modalproduct ////////////////// -->
    <Modal :id="`modal-${producto.id}`">
      <template #titulo>
        <h5 class="modal-title">
          Comprar
        </h5>
      </template>

      <!-- modalproduct - datos de producto ////////////////// -->

      <div class="single">
        <header class="single__product">
          <div class="single__thumbnail">
            <img class="producto__img" :src="assetsUrl+producto.imagen" alt="" />
          </div>
          <div class="single__summary">
            <h2 class="single__title">{{ producto.nombre }}</h2>
            <div class="single__hasta">
              <label>Disponible hasta</label>
              <time class="badge badge--gris">{{ producto.fecha_fin }}</time>
            </div>
            <div class="single__fecha" v-if="producto.fecha_evento">
              <label>Fecha del evento</label>
              <time class="badge badge--gris">{{ producto.fecha_evento }}</time>
            </div>
          </div>
        </header>

        <!-- prodalproduct - precios -->
        <section v-if="!procesandoPago" class="single__add">

          <!-- Usuario Actual -->
          <h3 class="head-4">
            <i class="bi bi-person-fill"></i> Pagar para ti
          </h3>
          <TiendaProductoAddSocio
            :socio="socio"
            :producto="producto"
            @change-selected="updateImporte"
          />

          <!-- Familiares -->
          <h3 v-if="familia.length > 0" class="head-4">
            <i class="bi bi-people-fill"></i> Pagar para familiares
          </h3>
          <TiendaProductoAddSocio v-for="socio in familia"
            :socio="socio"
            :producto="producto"
            @change-selected="updateImporte"
          />
        </section>

        <div class="totals">
          <strong>Total:</strong>
          {{ importe }} <sup>€</sup>
        </div>

        <CreditCard v-show="!procesandoPago" ref="fpagSocio" :light="true" />

        <template v-if="procesandoPago">
          <div class="text-center py-5">
            <div class="spinner-grow text-primary mb-4" role="status">
              <span class="visually-hidden">Espere porfavor...</span>
            </div>
            <br />
            <b>Redireccionando a pasarela de pagos</b>
          </div>
        </template>

        <!-- prodalproduct - precios -->
      </div>

      <!-- modalproduct - datos de producto ////////////////// -->

      <template #footer>
        <button type="button" class="boton boton--gris" data-bs-dismiss="modal">
          Cerrar
        </button>
        <button
          v-if="fpagSocio.datosTarjeta?.titular !== '' && importe > 0 && !procesandoPago"
          type="button"
          class="boton boton--principal"
          @click="startPay"
        >Proceder al pago</button>
      </template>
    </Modal>
    <!-- modalproduct ////////////////// -->
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onBeforeMount, PropType, ref, toRaw } from "vue";
import Title from "@/components/layout/Title.vue";
import Modal from "@/components/ui/Modal.vue";
import TiendaProductoAddSocio from "@/views/tienda/components/TiendaProductoAddSocio.vue";
import CreditCard from "@/views/perfil/components/CreditCard.vue";
import { useStore } from "@/store";
import { Producto } from "@/models/tienda/Producto";
import { User } from "@/store/modules/user/interfaces";

export default defineComponent({
  name: "Tienda",
  components: {
    Title,
    Modal,
    TiendaProductoAddSocio,
    CreditCard,
  },
  props: {
    producto: {
      type: Object as PropType<Producto>,
      default: {},
    }
  },
  setup({ producto }) {
    const store = useStore();
    const assetsUrl = store.state.assetsUrl;

    const isLoading = ref(true);
    const procesandoPago = ref(false);
    const fpagSocio = ref({} as any);
    const importe = ref(0);
    let socios = [] as any[];

    onBeforeMount(async () => {
      // Obtener familiares
      await store.dispatch("user/getFamilia");
      isLoading.value = false;
    })

    // Actualizar importe a pagar
    const updateImporte = (payload: { socio: User, importe: number, enabled: boolean }) => {
      // Añadir o quitar socio
      if (payload.enabled) {
        socios.push({
          id: payload.socio.id,
          tarifa: {
            id: payload.socio.tipo_socio.id,
            descripcion: payload.socio.tipo_socio.descripcion
          },
          importe: payload.importe
        })
      } else {
        socios = socios.filter(socio => socio.id !== payload.socio.id);
      }

      // Sumar o restar importe
      importe.value += payload.enabled ? payload.importe : -payload.importe;
    }

    // Iniciar pago
    const startPay = async () => {
      procesandoPago.value = true;

      const payload = {
        producto: producto.id,
        socios,
        total: toRaw(importe.value),
        fpag: toRaw(fpagSocio.value.datosTarjeta)
      }

      // Enviar peticion de pago
      const res = await store.dispatch('user/pagarProducto', payload);

      console.log('respuesta proceso de pago tienda', res);

      // Redireccionar si es mediante pasarela de pagos.
      // Creamos un form oculto para hacer la peticion
      const form = document.createElement('form');
      form.method = 'post';
      form.action = res.request.url;

      const params = {
        "Ds_Signature": res.request.ds_signature,
        "Ds_SignatureVersion": res.request.ds_signature_version,
        "Ds_MerchantParameters": res.request.ds_merchant_parameters
      };

      for (const [key, value] of Object.entries(params)) {
        const hiddenField = document.createElement('input');
        hiddenField.type = 'hidden';
        hiddenField.name = key;
        hiddenField.value = value;

        form.appendChild(hiddenField);
      }

      // Redireccion en misma ventana, ya que en otra distinta genera problemas de bloqueo popup
      window.document.body.appendChild(form);
      form.submit();

      console.log('Transaccion ID', res.transaccion_id);
    }

    return {
      assetsUrl,
      entityName: store.state.entityName,
      entityType: store.state.entityType,
      socio: computed(() => store.state.user.perfil),
      familia: computed(() => store.state.user.familia),
      isLoading,
      procesandoPago,
      fpagSocio,
      importe,
      updateImporte,
      startPay
    };
  },
});
</script>

<style lang="scss"></style>
