<template lang="">
  <aside class="header">
    <h4 class="header__pretitle">
      {{ pretitle }}
    </h4>
    <h1 class="header__title">{{ title }}</h1>
  </aside>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "Title",
  props: {
    pretitle: String,
    title: String,
  },
});
</script>

<style lang="scss"></style>
