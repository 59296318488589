
import { computed, defineComponent, reactive, ref, watch } from "vue";
import Title from "@/components/layout/Title.vue";
import PerfilFicha from "@/views/perfil/components/PerfilFicha.vue";
import PerfilAddFamiliar from "@/views/perfil/components/PerfilAddFamiliar.vue";
import Modal from "@/components/ui/Modal.vue";
import { useStore } from "@/store";

export default defineComponent({
  name: "PerfilFamiliar",
  components: {
    Title,
    PerfilFicha,
    Modal,
    PerfilAddFamiliar,
  },
  setup() {
    const store = useStore();
    const username = computed(
      () =>
        `${store.state.user.perfil.nombre} ${store.state.user.perfil.apellido_1}`
    );
    const modalAddFamiliar = ref();

    // Obtener familiares
    store.dispatch("user/getFamilia");

    return {
      username,
      familia: computed(() => store.state.user.familia),
      modalAddFamiliar,
    };
  },
});
