
import { computed, defineComponent, PropType, ref } from "vue";
import { useStore } from "@/store";
import Modal from "@/components/ui/Modal.vue";

import { User } from "@/store/modules/user/interfaces";
import PerfilScannerDNI from "@/views/perfil/components/PerfilScannerDNI.vue";

export default defineComponent({
  name: "PerfilEdit",
  components: {
    Modal,
    PerfilScannerDNI,
  },
  props: {
    perfil: {
      type: Object as PropType<User>,
      default: {},
    },
    isFamiliar: {
      type: Boolean,
      default: false,
    },
    isOnboarding: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["onSaveProfile"],
  setup(props, context) {
    const store = useStore();
    const editedPerfil = ref(computed(() => props.perfil));
    const telefono = ref("");

    const confirmDeleteFamiliar = ref("");

    // const savePerfil = () => {
    //   editedPerfil.value.telefono = telefono.value;
    //   context.emit('onSaveProfile', editedPerfil.value);
    // }

    const savePerfil = async () => {
      console.log("Guardando perfil editado", editedPerfil.value);

      // Modificar un familiar
      if (props.isFamiliar) {
        const success = await store.dispatch(
          "user/editFamiliar",
          editedPerfil.value
        );
        if (success) {
          console.log("Familiar actualizado correctamente");
        } else {
          console.log("Error al actualizar el familiar");
        }

        context.emit("onSaveProfile", editedPerfil.value);
        return success;
      }

      // Modificar perfil propio
      if (!props.isFamiliar) {
        // Check if isOnboarding
        if (props.isOnboarding) {
          editedPerfil.value.onboarding_ok = true;
        }

        const success = await store.dispatch(
          "user/editProfile",
          editedPerfil.value
        );
        if (success) {
          console.log("Perfil actualizado correctamente");
        } else {
          console.log("Error al actualizar el perfil");
        }

        context.emit("onSaveProfile", editedPerfil.value);
        return success;
      }
    };

    const deleteFamiliar = async () => {
      if (!props.isFamiliar) {
        return false;
      }

      const success = await store.dispatch(
        "user/deleteFamiliar",
        editedPerfil.value
      );
    };

    return {
      isFamiliar: computed(() => props.isFamiliar),
      editedPerfil,
      telefono,
      confirmDeleteFamiliar,
      savePerfil,
      deleteFamiliar,
    };
  },
});
