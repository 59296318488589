import { Mutation } from "vuex";
import { AuthState } from "./interfaces";

export const loggedIn: Mutation<AuthState> = (state, payload) => {
    state.loggedIn = true;
    state.loginError = '';
}

export const loggedOut: Mutation<AuthState> = (state, payload) => {
    state.loggedIn = false;
}

export const loginError: Mutation<AuthState> = (state, payload) => {
    state.loginError = payload;
}
