import { Mutation } from "vuex";
import { UserState } from "./interfaces";

export const setLoading: Mutation<UserState> = (state, payload) => {
    state.isLoading = payload;
}

export const setProfile: Mutation<UserState> = (state, payload) => {
    state.perfil = payload;
    state.isLoading = false;
}

export const setFamilia: Mutation<UserState> = (state, payload) => {
    state.familia = payload;
}

export const setTickets: Mutation<UserState> = (state, payload) => {
    state.tickets = payload;
}