<template>
  <div class="nota">
    <div class="nota__ico">
      <img src="../../assets/ico/ico-info.svg" alt="" />
      <span>{{ msgNotaTitutlo }}</span>
    </div>
    <div class="nota__content">
      {{ msgNota }}
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "Nota",
  props: {
    msgNota: String,
    msgNotaTitutlo: String,
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/scss/base/_variables.scss";

.nota {
  border-radius: 1rem;
  box-shadow: $box-shadow-cards;
  margin: 1rem 0;
  width: 100%;
  padding: 1rem;
  background: $color-blanco;
  text-align: left;

  &--alert {
    background: rgb(255, 225, 236);
    border: 2px solid rgb(188, 144, 176);
  }

  &__ico {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
    img {
      width: 20px;
      height: auto;
      margin-right: 8px;
    }
    span {
      text-transform: uppercase;
      font-weight: bold;
      font-size: 0.8rem;
    }
  }

  &__content {
    text-align: left;
    font-size: 0.8rem;
  }
}
</style>
