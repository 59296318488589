
import { useStore } from "@/store";
import { defineComponent, onMounted, onUnmounted } from "vue";
export default defineComponent({
  name: "TopBar",
  props: {
    topTitle: String,
  },
  setup() {
    const store = useStore();

    const logout = () => {
      store.dispatch("auth/logout");
    };

    const handleSCroll = (event: any) => {
      let header = document.querySelector(".top-nav");
      if (
        header &&
        window.scrollY > 20 &&
        !header.className.includes("v-toolbar--bgchange")
      ) {
        // console.log(window.scrollY);
        header.classList.add("top-nav--scroll");
      } else if (header && window.scrollY < 20) {
        header.classList.remove("top-nav--scroll");
      }
    };

    onMounted(() => {
      window.addEventListener("scroll", handleSCroll);
    });

    onUnmounted(() => {
      window.removeEventListener("scroll", handleSCroll);
    });

    return {
      store,
      logout,
      handleSCroll,
    };
  },
});
