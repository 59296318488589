
import { defineComponent, ref } from "vue";
import { useRouter } from "vue-router";
import auth from "@/services/api/auth";
import Nota from "@/components/alerts/Nota.vue";

export default defineComponent({
  name: "LoginReset",
  components: {
    Nota,
  },
  setup() {
    const router = useRouter();

    const dniInput = ref("");
    const isSendingEmail = ref(false);
    const error = ref("");

    const sendMailResetPassword = async () => {
      isSendingEmail.value = true;

      const res = await auth
        .recoveryPassword(dniInput.value)
        .then((res) => res.data)
        .catch((err) => {
          error.value = err.response.data.email[0];
        });

      if (res?.ok) {
        error.value = "";
        router.push("/login/success");
      } else if (res?.message) {
        error.value = res.message;
      }

      isSendingEmail.value = false;
    };

    return {
      dniInput,
      sendMailResetPassword,
      isSendingEmail,
      error,
    };
  },
});
