
import { computed, defineComponent, ref } from "vue";
import { useStore } from "@/store";
import Nota from "@/components/alerts/Nota.vue";
import router from "@/router";

export default defineComponent({
  components: { Nota },
  name: "Login",
  setup() {
    const store = useStore();

    const dni = ref("");
    const password = ref("");
    const isLogginIn = computed(() => store.state.isLoading);
    const error = computed(() => store.state.auth.loginError);

    // Reset estado
    store.dispatch("clearAllState");

    const login = async () => {
      const success = await store.dispatch("auth/login", {
        dni: dni.value,
        password: password.value,
      });

      if (success) {
        router.replace("/");
      }
    };

    return {
      entityName: store.state.entityName,
      entityType: store.state.entityType,
      dni,
      password,
      login,
      isLogginIn,
      error,
    };
  },
});
