<template>
  <p>
    Añade a los socios miembros de tu familia a tu cuenta de usuario. Solo se
    mostraran resultados que coincidan con alguno de tus apellidos.
  </p>

  <div class="form-floating mb-3">
    <input
      type="text"
      class="form-control"
      id="floatingInput"
      placeholder="DNI"
      v-model="dni"
    />
    <label for="floatingInput">DNI</label>
  </div>
  <div class="form-floating mb-3">
    <input
      type="date"
      class="form-control"
      id="floatingInput"
      placeholder="Fecha de nacimiento"
      v-model="fechaNacimiento"
    />
    <label for="floatingInput">Fecha de nacimiento</label>
  </div>

  <button class="boton boton--principal boton--block" @click="searchFamiliar">
    Buscar
  </button>

  <div class="my-4" v-for="user in resultado" :key="user.id">
    <div class="add-resultados">
      <div class="add-resultados__item">
        <div class="form-check form-switch form-switch-md">
          <input
            class="form-check-input"
            type="checkbox"
            id="flexSwitchCheckDefault"
            v-model="selected"
          />
          <label class="form-check-label" for="flexSwitchCheckDefault"
            >{{ `${user.nombre} ${user.apellido_1} ${user.apellido_2}` }} <span>{{ user.dni }}</span></label
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import axiosApi from "@/services/axios";
import { useStore } from "@/store";

import { User } from "@/store/modules/user/interfaces";

export default defineComponent({
  name: "PerfilAddFamiliar",
  components: {},
  setup() {
    const store = useStore();

    const dni = ref('');
    const fechaNacimiento = ref('');
    const resultado = ref([] as User[]);
    const selected = ref(false);

    const searchFamiliar = async () => {
      const familiar = await axiosApi.post('/familia/search', {
        dni: dni.value,
        fechaNacimiento: fechaNacimiento.value
      }).then(res => res.data);

      if (familiar.length > 0) {
        selected.value = true;
      }

      resultado.value = familiar;
    }

    const guardarFamiliar = async () => {
      if (selected.value) {
        console.log('Añadiendo familiar', resultado.value[0]);
        const res = await axiosApi.post('/familia/add', {
          dni: dni.value,
          fechaNacimiento: fechaNacimiento.value
        }).then(r => r.data);
        
        if (res.ok) {
          store.dispatch('user/getFamilia');
        }
      }

      dni.value = '';
      fechaNacimiento.value = '';
      resultado.value = [];
      selected.value = false;
    }

    return {
      dni,
      fechaNacimiento,
      resultado,
      selected,
      searchFamiliar,
      guardarFamiliar
    }
  }
});
</script>
<style lang="scss"></style>
