<template>
  <section class="wrapper">
    <div class="container">
      <Title
        :pretitle="`${entityType} ${entityName}`"
        title="Compras / Servicios"
      ></Title>

      <!-- <CommingSoon
        msgComming="Disponible en breve"
        imageUrl="ico-dash-comming.svg"
      ></CommingSoon> -->

      <section v-if="!isLoading" class="productos">
        <tiendaProducto v-for="producto in productos" :producto="producto" />
      </section>
    </div>
  </section>
</template>

<script lang="ts">
import { defineComponent, onBeforeMount, ref } from "vue";
import Title from "@/components/layout/Title.vue";
import CommingSoon from "@/components/alerts/CommingSoon.vue";
import TiendaProducto from "@/views/tienda/components/TiendaProducto.vue";
import { useStore } from "@/store";
import { Producto, Pvp } from "@/models/tienda/Producto";
import tiendaService from "@/services/api/tiendaService";

export default defineComponent({
  name: "Tienda",
  components: {
    Title,
    CommingSoon,
    TiendaProducto,
  },
  setup() {
    const store = useStore();
    const isLoading = ref(true);

    const productos = ref([] as Producto[]);

    onBeforeMount(async () => {      
      // TODO: comprobar si el producto aplica al socio o familiares y sino excluirlo
      productos.value = (await tiendaService.getProductos())
        .filter((producto: Producto) => {
          let available = false;
          producto.pvp.forEach((pvp: Pvp) => {
            if (store.state.user.perfil.tipo_socio.id === pvp.id_tarifa) {
              available = true;
            } else {
              console.log(store.state.user.familia);
              store.state.user.familia.forEach(familia => {
                if (familia.tipo_socio.id === pvp.id_tarifa) {
                  available = true;
                }
              });
            }
          });

          return available;
        });

      await store.dispatch("user/getTickets");
      
      isLoading.value = false;
    })

    return {
      entityName: store.state.entityName,
      entityType: store.state.entityType,
      isLoading,
      productos
    };
  },
});
</script>

<style lang="scss"></style>
