
import { defineComponent, ref } from "vue";
import Title from "@/components/layout/Title.vue";
import { useStore } from "@/store";
import CommingSoon from "@/components/alerts/CommingSoon.vue";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "PagoCorrecto",
  components: {
    CommingSoon,
    Title,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const correcto = ref(route.path === "/redsys-respuesta/ok");

    return {
      entityName: store.state.entityName,
      entityType: store.state.entityType,
      correcto,
    };
  },
});
