
import { defineComponent } from "vue";

export default defineComponent({
  name: "Title",
  props: {
    pretitle: String,
    title: String,
  },
});
