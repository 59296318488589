<template>
  <div class="card card--card card--card-max card--dark">
    <h2 class="head-3">{{ nombre }} {{ apellido1 }} {{ apellido2 }}</h2>

    <div class="card-socio">
      <div class="card-socio__ico">
        <img src="@/assets/ico/ico-badge.svg" alt="" />
      </div>
      <div class="card-socio__content">
        <div class="card-socio__title">
          <strong>{{ tipoSocio }} </strong> {{ entityName }}
        </div>
        <div class="card-socio__subtitl">
          <div>
            <span
              class="badge"
              :class="estadoSocio ? 'badge--verde' : 'badge--rojo'"
            >
              {{ estadoSocio ? "Activo"
                : idSocio === currentUser ? 'Actualmente no eres socio' : 'Actualmente no es socio' 
              }}
            </span>
          </div>
          <div v-if="parent && currentUser !== idSocio" class="mt-2">
            Familiar vinculado a tu usuario
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { computed, defineComponent } from "vue";
import { useStore } from "@/store";

export default defineComponent({
  name: "CardSocio",
  props: {
    idSocio: Number,
    nombre: String,
    apellido1: String,
    apellido2: String,
    tipoSocio: String,
    estadoSocio: Boolean,
    parent: Number,
  },
  setup() {
    const store = useStore();
    const currentUser = computed(() => store.state.user.perfil.id);
    
    return {
      entityName: store.state.entityName,
      entityType: store.state.entityType,
      currentUser
    }
  }
});
</script>
<style lang=""></style>
