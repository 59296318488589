<template>
  <!-- TODO: Corregir esto, aplicar otra logica -->
  <TopBar
    :topTitle="`${entityType} ${entityName}`"
    v-if="
      $route.path.startsWith('/login') ||
      $route.path.startsWith('/login/reset') ||
      $route.path.startsWith('/login/new-password') ||
      $route.path.startsWith('/login/success') ||
      $route.path.startsWith('/politica-privacidad')
        ? false
        : true
    "
  ></TopBar>

  <router-view v-slot="{ Component }">
    <transition name="scale" mode="out-in">
      <component :is="Component" />
    </transition>
  </router-view>

  <NavBarResponsive
    v-if="
      $route.path.startsWith('/login') ||
      $route.path.startsWith('/login/reset') ||
      $route.path.startsWith('/login/success') ||
      $route.path.startsWith('/login/new-password') ||
      $route.path.startsWith('/onboarding/step-1') ||
      $route.path.startsWith('/onboarding/step-2') ||
      $route.path.startsWith('/politica-privacidad')
        ? false
        : true
    "
  ></NavBarResponsive>
</template>

<script lang="ts">
import { defineComponent, onBeforeMount } from "vue";
import NavBarResponsive from "@/components/layout/NavBarResponsive.vue";
import TopBar from "@/components/layout/TopBar.vue";
import { store, useStore } from "./store";

export default defineComponent({
  name: "Perfil",
  components: {
    NavBarResponsive,
    TopBar,
  },

  watch: {
    $route(to, from) {
      document.title = to.meta.title || "Tot a Orri App";
    },
  },

  setup() {
    const store = useStore();

    console.log("Iniciando apliacion");

    onBeforeMount(() => {
      if (store.getters.isAuthenticated) {
        store.commit("auth/loggedIn");
      }

      // Cargar perfil del usuario si esta login
      if (!store.state.user.perfil.nombre && store.state.auth.loggedIn) {
        console.log("Obteniendo perfil");
        store.dispatch("user/loggedIn");
      }
    });

    return {
      entityName: store.state.entityName,
      entityType: store.state.entityType,
    };
  },
});
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
}

#nav {
  padding: 30px;
}

.scale-enter-active,
.scale-leave-active {
  transition: all 0.3s ease;
}

.scale-enter-from,
.scale-leave-to {
  opacity: 0;
  transform: scale(0.9);
}
</style>
