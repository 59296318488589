<template>
  <div class="wrapper">
    <div class="container">
      <Title pretitle="David Pruñonosa" title="Revisa tus datos"></Title>
      <div class="card mb-3">
        <PerfilFichaEdit
          :perfil="perfil"
          :isFamiliar="false"
          :isOnboarding="true"
          ref="perfilEdit"
        ></PerfilFichaEdit>
        <!-- <router-link
          to="/onboarding/step-2"
          class="boton boton--principal boton--block"
          @click="perfilEdit.savePerfil()"
          >Siguiente <i class="bi bi-arrow-right"></i
        ></router-link> -->
        <button class="boton boton--principal boton--block" @click="savePerfil">
          Finalizar <i class="bi bi-arrow-right"></i>
        </button>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { computed, defineComponent, ref } from "vue";
import PerfilFichaEdit from "@/views/perfil/components/PerfilFichaEdit.vue";
import Title from "@/components/layout/Title.vue";
import { useStore } from "@/store";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "OnboardingStep1",
  components: {
    PerfilFichaEdit,
    Title,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const perfilEdit = ref();
    const perfil = computed(() => store.state.user.perfil);

    const savePerfil = async () => {
      const success = await perfilEdit.value.savePerfil();
      if (success) {
        router.replace('/');
      }
    }

    return {
      perfilEdit,
      perfil,
      savePerfil
    }
  }
});
</script>
<style lang=""></style>
