<template>
  <div class="card card--card mb-0">
    <TicketsTicketSummary :summary="ticketSummary" />

    <div class="card__actions">
      <button
        class="boton boton--principal boton--block"
        data-bs-toggle="modal"
        :data-bs-target="`#modal-${ticketSummary.id}`"
      >
        <i class="bi bi-upc-scan"></i> Ver detalles
      </button>
    </div>
  </div>

  <Modal :id="`modal-${ticketSummary.id}`">
    <template #titulo>
      <h5 class="modal-title">
        Ticket
      </h5>
    </template>

    <TicketsTicketSummary :summary="ticketSummary" />
    <TicketsTicketSummarySocios :tickets="tickets" />

    <template #footer>
      <button
        type="button"
        class="boton boton--principal boton--block"
        data-bs-dismiss="modal"
      >
        Cerrar
      </button>
    </template>
  </Modal>
</template>

<script lang="ts">
import { defineComponent, PropType, toRaw } from "vue";
import Modal from "@/components/ui/Modal.vue";
import TicketsTicketSummary from "@/views/tickets/components/TicketsTicketSummary.vue";
import TicketsTicketSummarySocios from "@/views/tickets/components/TicketsTicketSummarySocios.vue";
import { Ticket } from "@/models/tienda/Ticket";

export default defineComponent({
  name: "TicketsTicket",
  components: {
    Modal,
    TicketsTicketSummary,
    TicketsTicketSummarySocios,
  },
  props: {
    ticket: {
      type: Object as PropType<Ticket[]>,
      required: true,
    },
  },
  setup(props) {
    const tickets = toRaw(props.ticket);

    const ticketSummary = {
      id: tickets[0].id,
      descripcion: tickets[0].descripcion,
      total: tickets.map((ticket) => ticket.importe).reduce((a, b) => a + b),
    }

    return {
      tickets,
      ticketSummary,
    };
  }
});
</script>

<style lang=""></style>
