
import { computed, defineComponent, ref } from "vue";
import PerfilFichaEdit from "@/views/perfil/components/PerfilFichaEdit.vue";
import Title from "@/components/layout/Title.vue";
import { useStore } from "@/store";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "OnboardingStep1",
  components: {
    PerfilFichaEdit,
    Title,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const perfilEdit = ref();
    const perfil = computed(() => store.state.user.perfil);

    const savePerfil = async () => {
      const success = await perfilEdit.value.savePerfil();
      if (success) {
        router.replace('/');
      }
    }

    return {
      perfilEdit,
      perfil,
      savePerfil
    }
  }
});
