<template>
  <div class="ticket-checkout">
    <!-- <div class="head-3">Resumen de la compra</div> -->
    <div class="ticket-checkout__list">
      <!-- TICKET SOCIO -->
      <h3 class="head-3"><i class="bi bi-person-fill me-1"></i> Tu ticket</h3>
      <div v-if="socioTicket?.id_transaccion" class="ticket-checkout__item">
        <div class="ticket-checkout__qr">
          <div class="ticket-checkout__redsys">
            Código:
            <strong>{{ socioTicket.id_transaccion }}</strong>
          </div>

          <img
            :src="getQrUrlBySocioId(socioTicket.id_socio)"
            :alt="socioTicket.id_transaccion"
          />
        </div>

        <div class="ticket-checkout__left">
          <time class="ticket-checkout__time">
            <strong><i class="bi bi-credit-card me-1"></i> Pagado:</strong>
            {{ socioTicket.fecha }}
          </time>
          <div class="ticket-checkout__name">
            {{ socioTicket.socio.nombre }} {{ socioTicket.socio.apellido_1 }}
            {{ socioTicket.socio.apellido_2 }}
          </div>
          <div class="ticket-checkout__tipo">
            <div
              class="badge badge--verde"
              v-if="socioTicket.tarifa == 'Socio'"
            >
              Socio
            </div>
            <div
              class="badge badge--naranja"
              v-if="socioTicket.tarifa == 'Colaborador'"
            >
              Colaborador
            </div>
            <div
              class="badge badge--azul"
              v-if="socioTicket.tarifa == 'Socio Infantil'"
            >
              Socio Infantil
            </div>
          </div>
        </div>
        <div class="ticket-checkout__right">
          <div class="ticket-checkout__cantidad">
            {{ socioTicket.importe }} <sup>€</sup>
          </div>
        </div>
      </div>

      <!-- TICKET FAMILIA -->
      <h3 class="head-3">
        <i class="bi bi-people-fill me-1"></i> Tickets de tu familia
      </h3>
      <template v-if="familiarTicket">
        <div v-for="familiar in familiarTicket" class="ticket-checkout__item">
          <div class="ticket-checkout__qr">
            <div class="ticket-checkout__redsys">
              Código:
              <strong>{{ socioTicket.id_transaccion }}</strong>
            </div>
            <img
              :src="getQrUrlBySocioId(familiar.id_socio)"
              :alt="familiar.id_transaccion"
            />
          </div>

          <div class="ticket-checkout__left">
            <time class="ticket-checkout__time">
              <strong><i class="bi bi-credit-card me-1"></i> Pagado:</strong>
              {{ familiar.fecha }}
            </time>
            <div class="ticket-checkout__name">
              {{ familiar.socio.nombre }} {{ familiar.socio.apellido_1 }}
              {{ familiar.socio.apellido_2 }}
            </div>
            <div class="ticket-checkout__tipo">
              <div class="badge badge--verde">{{ familiar.tarifa }}</div>
            </div>
          </div>
          <div class="ticket-checkout__right">
            <div class="ticket-checkout__cantidad">
              {{ familiar.importe }} <sup>€</sup>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import { Ticket } from "@/models/tienda/Ticket";
import tiendaService from "@/services/api/tiendaService";
import { useStore } from "@/store";
import { defineComponent, onMounted, PropType, ref } from "vue";
import TicketsTicketSummaryVue from "./TicketsTicketSummary.vue";

export default defineComponent({
  name: "TicketsTicketSummarySocios",
  components: {},
  props: {
    tickets: {
      type: Object as PropType<Ticket[]>,
      default: () => [],
    },
  },
  setup(props) {
    const store = useStore();
    const socio = store.state.user.perfil;
    const urlsQr = ref([] as any[]);

    const socioTicket: Ticket =
      props.tickets.find((ticket) => ticket.socio.id === socio.id) ??
      ({} as Ticket);

    const familiarTicket: Ticket[] = props.tickets.filter(
      (ticket) => ticket.socio.id !== socio.id
    );

    const loadQrImages = async () => {
      // Socio QR
      urlsQr.value.push({
        id: socio.id,
        qrUrl: await tiendaService.getTicketQrImage(socioTicket.id),
      });

      // Familia QR
      familiarTicket.forEach(async (ticket) => {
        urlsQr.value.push({
          id: ticket.socio.id,
          qrUrl: await tiendaService.getTicketQrImage(ticket.id),
        });
      });
    };

    const getQrUrlBySocioId = (socioId: number) => {
      return urlsQr.value.find((url) => url.id === socioId)?.qrUrl;
    };

    onMounted(() => {
      loadQrImages();
    });

    return {
      socioTicket,
      familiarTicket,
      getQrUrlBySocioId,
    };
  },
});
</script>

<style lang=""></style>
