import { Action } from "vuex";
import auth from "@/services/api/auth";

import { State } from "@/store";
import { AuthState } from "./interfaces";
import { TokenStorage } from "@/services/tokenStorage";
import router from "@/router";

export const login: Action<AuthState, State> = async ({ commit, dispatch }, payload) => {
    commit("changeAppLoadingState", true, { root: true });

    const success = auth.login(payload.dni, payload.password)
    .then(res => {
        dispatch("user/loggedIn", null, { root: true });
        commit("changeAppLoadingState", false, { root: true });
        return true;
    })
    .catch(err => {
        commit("loginError", err.response.data || "Error");
        commit("changeAppLoadingState", false, { root: true });
        return false;
    });

    return success;
};

export const logout: Action<AuthState, State> = async ({ dispatch, commit, state }) => {
    TokenStorage.clear();
    commit("loggedOut");
    router.replace('login');
}
