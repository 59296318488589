<template lang="">
  <!-- <h3 class="head-5 head--upper head--light">Acceso rápido</h3> -->
  <div class="dashboard-nav">
    <router-link to="/socio" class="dashboard-nav__item">
      <div class="dashboard-nav__ico">
        <img src="@/assets/ico/ico-dash-cuotas.svg" alt="" />
      </div>
      <div class="dashboard-nav__titulo">
        Pago de cuotas
        <span>Socios</span>
      </div>
    </router-link>
    <router-link to="/tickets" class="dashboard-nav__item">
      <div class="dashboard-nav__ico">
        <img src="@/assets/ico/ico-ticket-color.png" alt="" />
      </div>
      <div class="dashboard-nav__titulo">
        Tickets
        <span>Compras realizadas</span>
      </div>
    </router-link>
    <router-link to="/tienda" class="dashboard-nav__item">
      <div class="dashboard-nav__ico">
        <img src="@/assets/ico/ico-dash-shop.svg" alt="" />
      </div>
      <div class="dashboard-nav__titulo">
        Tienda
        <span>Compras online</span>
      </div>
    </router-link>
    <router-link to="/perfil/familia" class="dashboard-nav__item">
      <div class="dashboard-nav__ico">
        <img src="@/assets/ico/ico-dash-family.svg" alt="" />
      </div>
      <div class="dashboard-nav__titulo">
        Familia
        <span>Añade miembros</span>
      </div>
    </router-link>
    <router-link to="/perfil" class="dashboard-nav__item">
      <div class="dashboard-nav__ico">
        <img src="@/assets/ico/ico-dash-mask.svg" alt="" />
      </div>
      <div class="dashboard-nav__titulo">
        Perfil
        <span>Datos personales</span>
      </div>
    </router-link>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "HomeDashboard",
});
</script>

<style lang=""></style>
