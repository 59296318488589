import axios from "@/services/axios";
import { TokenStorage } from "../tokenStorage";

export default {

  login(dni: string, password: string) {
    return axios.post('/auth/login', {
      dni,
      password
    }).then(res => {
      TokenStorage.storeToken(res.data.token);
      res.data = true;
      return res;
    });
  },

  recoveryPassword(dni: string) {
    return axios.post(`/auth/recover`, {
      dni,
    });
  },

  async verifyRecoveryToken(token: string) {
    return await axios.get(`/auth/recover/${token}`).then(res => res.data);
  },

  async resetPassword(password: string, password_confirmation: string, token: string) {
    return await axios.post(`/auth/recover/${token}/reset-password`, {
      token,
      password,
      password_confirmation
    })
    .then(res => res.data);
  }
};
