
import { defineComponent, onBeforeMount, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import auth from "@/services/api/auth";
import Nota from "@/components/alerts/Nota.vue";
import { User } from "@/store/modules/user/interfaces";
import axios from "axios";

export default defineComponent({
  name: "LoginResetPassword",
  components: {
    Nota
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const token = route.params.token as string;
    const user = ref({} as User);
    const password = ref('');
    const password_confirmation = ref('');

    const isLoading = ref(true);
    const isValidToken = ref(false);
    const isReseting = ref(false);
    const isPasswordReset = ref(false);
    const error = ref("");

    onBeforeMount(async () => {
      console.log('verificando token');
      isLoading.value = true;
      let res: any;

      await new Promise((resolve, reject) => {
        setTimeout(async () => {
          res = await auth.verifyRecoveryToken(token);
          resolve(true);
        }, 3000)
      });

      // Si el token no es valido, lo indicamos y volvemos a la pagina de login
      if (!res.ok) {
        isValidToken.value = false;
        error.value = res.message;
        isLoading.value = false;
        return;
      }

      // Token valido
      console.log(res.user);
      user.value = res.user;
      isValidToken.value = true;
      isLoading.value = false;
    })

    const validatePassword = () => {
      const samePassword = password.value === password_confirmation.value;
      const lengthPassword = password.value.trim().length >= 6;
      const hasUpperCase = /[A-Z]/.test(password.value);
      const hasLowerCase = /[a-z]/.test(password.value);
      const hasNumber = /[0-9]/.test(password.value);
      
      if (!samePassword) {
        error.value = 'Las contraseñas no coinciden'
      } else if (!lengthPassword) {
        error.value = 'La contraseña debe tener como minimo 6 caracteres'
      } else if (!hasUpperCase) {
        error.value = 'La contraseña debe tener al menos 1 mayuscula'
      } else if (!hasLowerCase) {
        error.value = 'La contraseña debe tener al menos 1 minuscula'
      } else if (!hasNumber) {
        error.value = 'La contraseña debe tener al menos 1 número'
      } else {
        error.value = '';
        return true;
      }

      return false;
    }

    // Enviar peticion de cambio de contraseña
    const resetPassword = async () => {
      if (!validatePassword()) {
        return false;
      }

      console.log('Enviando peticion de cambio de contraseña');
      isReseting.value = true;
      const res = await auth.resetPassword(password.value, password_confirmation.value, token);
      if (res.ok) {
        isPasswordReset.value = true;
      } else {
        isValidToken.value = false;
        error.value = res.message;
      }
      isReseting.value = false;
      console.log('respuesta cambio de contraseña', res);
    }

    return {
      user,
      password,
      password_confirmation,
      validatePassword,
      resetPassword,
      isLoading,
      isValidToken,
      isReseting,
      isPasswordReset,
      error,
    };
  },
});
