<template>
  <section class="wrapper">
    <div class="container">
      <div class="login-box">
        <div class="login-box__content">
          <img
            class="login-box__ico"
            src="@/assets/logos/logo-login.svg"
            alt="Login"
          />
          <h2 class="login-box__h2">Acceso socios</h2>
          <h1 class="login-box__h1">{{ entityType }} {{ entityName }}</h1>

          <nota
            v-if="error"
            msgNotaTitutlo="Error de acceso"
            msgNota="El dni o contraseña introducidos no son validos."
          />

          <div class="login-box__login">
            <div class="form-floating mb-3">
              <input
                type="text"
                class="form-control"
                id="floatingInput"
                placeholder="Dni"
                v-model="dni"
              />
              <label for="floatingInput">Dni</label>
            </div>
            <div class="form-floating">
              <input
                type="password"
                class="form-control"
                id="floatingPassword"
                placeholder="Contraseña"
                v-model="password"
              />
              <label for="floatingPassword">Contraseña</label>
            </div>
            <div class="login-box__actions">
              <button
                class="boton boton--principal boton--block mb-2"
                @click="login"
                :disabled="isLogginIn"
              >
                {{ isLogginIn ? "Accediendo..." : "Acceso socios" }}
              </button>
              <router-link to="/login/reset" class="link"
                >Recuperar contraseña</router-link
              >
            </div>
          </div>

          <div class="login-box__politicas">
            <router-link to="/politica-privacidad" class="link-min"
              >Política de privacidad</router-link
            >
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from "vue";
import { useStore } from "@/store";
import Nota from "@/components/alerts/Nota.vue";
import router from "@/router";

export default defineComponent({
  components: { Nota },
  name: "Login",
  setup() {
    const store = useStore();

    const dni = ref("");
    const password = ref("");
    const isLogginIn = computed(() => store.state.isLoading);
    const error = computed(() => store.state.auth.loginError);

    // Reset estado
    store.dispatch("clearAllState");

    const login = async () => {
      const success = await store.dispatch("auth/login", {
        dni: dni.value,
        password: password.value,
      });

      if (success) {
        router.replace("/");
      }
    };

    return {
      entityName: store.state.entityName,
      entityType: store.state.entityType,
      dni,
      password,
      login,
      isLogginIn,
      error,
    };
  },
});
</script>
