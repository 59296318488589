
import { computed, defineComponent } from "vue";
import Modal from "@/components/ui/Modal.vue";
import PerfilPagosEdit from "@/views/perfil/components/PerfilPagosEdit.vue";
import { useStore } from "@/store";

export default defineComponent({
  name: "PerfilPagos",
  components: {
    Modal,
    PerfilPagosEdit,
  },
  setup() {
    const store = useStore();

    return {
      pagoSiguienteFechaInicial: "07/09/2021",
      pagoSiguienteFechaFinal: "30/09/2021",
      tarjetaNumero: "0000 0000 0000 0000",
      tarjetaCaduca: "11/23",
      tipoPrecio: computed(() => store.state.user.perfil.tipo_socio.precio),
      tipoSocio: computed(() => store.state.user.perfil.tipo_socio.descripcion),
      pagoEstado: computed(() => store.state.user.perfil.pagos.status),
      tarjetaGuardada: computed(() => store.state.user.perfil.pagos.fpag_saved), // Tarjeta guardada o pago manual
    };
  },
});
