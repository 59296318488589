
import { computed, defineComponent } from "vue";
import NavBarResponsive from "@/components/layout/NavBarResponsive.vue";
import Title from "@/components/layout/Title.vue";
import HomeDashboard from "@/views/home/components/HomeDashboard.vue";
import { useStore } from "@/store";

export default defineComponent({
  name: "Home",
  components: {
    NavBarResponsive,
    HomeDashboard,
    Title,
  },
  setup() {
    const store = useStore();

    return {
      username: computed(() => store.state.user.perfil.nombre || '....')
    }
  }
});
