
import { defineComponent, onBeforeUnmount, onMounted, onUnmounted, ref } from "vue";
import * as bootstrap from 'bootstrap';

export default defineComponent({
  name: "Modal",
  setup() {
    const closeModalButton = ref();

    const closeModal = () => {
      closeModalButton.value.click();
    }

    onBeforeUnmount(() => {
      // Cerrar modal si estubiese abierto
      closeModal();
    })

    return {
      closeModalButton,
      closeModal
    }
  }
});
