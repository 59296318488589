
import { defineComponent } from "vue";

export default defineComponent({
  name: "Nota",
  props: {
    msgComming: String,
    imageUrl: String,
  },
});
