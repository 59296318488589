<template>
  <section class="">
    <div class="container">
      <div class="login-box">
        <div class="login-box__content">
          <div class="login-box__icon">
            <img
              class="login-box__ico login-box__ico--max"
              src="@/assets/ico/ico-msg.svg"
              alt="Login"
            />
          </div>
          <h1 class="login-box__h1">Revisa tu correo electrónico</h1>
          <div class="login-box__login">
            <div class="login-box__actions">
              <router-link
                to="/login"
                class="boton boton--principal boton--block mb-2"
                >Acceder</router-link
              >
              <div class="login-box__p">
                <strong> No has recibido el email?</strong> Comprueba tu bandeja
                de spam o
                <router-link to="/login/reset"
                  >prueba con otra dicrección.</router-link
                >.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "LoginResetSuccess",
});
</script>
