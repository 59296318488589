<template>
  <section class="">
    <div class="container">
      <div class="login-box login-box--left">
        <div class="login-box__content">
          <h1 class="login-box__h1 mb-2">Recupera tu contraseña</h1>
          <p>
            Introduce el DNI. Recibiras la
            contraseña en el correo asociado a tu cuenta de socio.
          </p>

          <Nota
            v-if="error.length > 0"
            msgNotaTitutlo="Error"
            :msgNota="error"
          ></Nota>

          <div class="login-box__login">
            <div class="form-floating mb-3">
              <input
                type="text"
                class="form-control"
                id="floatingInput"
                placeholder="DNI"
                v-model="dniInput"
              />
              <label for="floatingInput">DNI</label>
            </div>
            <div class="login-box__actions">
              <button
                class="boton boton--principal boton--block mb-2"
                @click="sendMailResetPassword"
                :disabled="isSendingEmail || dniInput.length < 9"
              >
                Enviar instrucciones
              </button>
              <router-link to="/login" class="link"
                >Volver al Inicio</router-link
              >
            </div>
          </div>
        </div>
        <Nota
          msgNotaTitutlo="Importante"
          msgNota="Si quieres vincular a los miembros de tu unidad familiar con tu usuario, podrás hacerlo una vez accedas a la aplicación."
        ></Nota>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { useRouter } from "vue-router";
import auth from "@/services/api/auth";
import Nota from "@/components/alerts/Nota.vue";

export default defineComponent({
  name: "LoginReset",
  components: {
    Nota,
  },
  setup() {
    const router = useRouter();

    const dniInput = ref("");
    const isSendingEmail = ref(false);
    const error = ref("");

    const sendMailResetPassword = async () => {
      isSendingEmail.value = true;

      const res = await auth
        .recoveryPassword(dniInput.value)
        .then((res) => res.data)
        .catch((err) => {
          error.value = err.response.data.email[0];
        });

      if (res?.ok) {
        error.value = "";
        router.push("/login/success");
      } else if (res?.message) {
        error.value = res.message;
      }

      isSendingEmail.value = false;
    };

    return {
      dniInput,
      sendMailResetPassword,
      isSendingEmail,
      error,
    };
  },
});
</script>
