<template>
  <div class="ticket">
    <div class="ticket__picture">
      <img class="" src="@/assets/ico/ico-ticket.png" alt="" />
    </div>
    <div class="ticket__content">
      <h2 class="ticket__producto">
        {{ summary.descripcion }}
      </h2>
      <!-- TODO: Añadir fecha del evento? o fecha de pago del ticket? -->
      <!-- <time class="ticket__time"> <strong>10/10/2021</strong> @ 20:30h </time> -->
      <div class="ticket__importe"><strong>Total:</strong> {{ summary.total }}€</div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

export default defineComponent({
  name: "TicketsTicketSummary",
  components: {},
  props: {
    summary: {
      type: Object as PropType<{
        descripcion: string,
        total: number
      }>,
      required: true
    }
  },
});
</script>

<style lang=""></style>
