import { store } from "@/store";
import axiosApi from "./axios";
import { TokenStorage } from "./tokenStorage";

export default {

  init() {
    // Añadir token a las peticiones de api, solo a las rutas que no son de auth
    axiosApi.interceptors.request.use((request) => {
      console.log(request.url);

      // añadir solo cuando la ruta sea distinta a /auth
      if (!request.url?.startsWith("/auth")) {
        request.headers.Authorization = TokenStorage.getAuthentication();
      }

      return request;
    });

    // Interceptar llamadas no authorizadas y realizar logout del usuario
    axiosApi.interceptors.response.use(
      (response) => {
        // Return successful response
        return response;
      },
      (error) => {
        // Si no es un error de autentificacion, devolvemos para manejar error normal.
        if (error.response.status !== 401) {
          return new Promise((resolve, reject) => {
            reject(error);
          });
        }

        // Si es un error de autentificacion, hacemos logout del usuario
        store.dispatch('auth/logout');

        return new Promise((resolve, reject) => {
          reject(error);
        });
      }
    );
  },
};
