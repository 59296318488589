<template lang="">
  <div class="card card--card">
    <h2 class="head-3">
      Configuración de pagos
    </h2>

    <div class="v-list">
      <div class="v-list__item" v-if="tarjetaGuardada">
        <div class="v-list__label">Pago con tarjeta</div>
        <div class="v-list__content">
          0000 0000 0000 0000 ·
          <small><strong>Caduca</strong> {{ tarjetaCaduca }}</small>
        </div>
      </div>

      <div class="v-list__item" v-else>
        <div class="v-list__label">Configura una tarjeta</div>
        <div class="v-list__content">
          <small class="text-danger">No tienes una tarjeta guardada</small>
        </div>
      </div>

      <div class="v-list__item">
        <div class="v-list__label">
          {{
            tarjetaGuardada
              ? "Configurado pago automático"
              : "Configurado pago manual"
          }}
        </div>
        <div class="v-list__content">
          <div v-if="tarjetaGuardada">
            Siguiente pago {{ pagoSiguienteFechaInicial }} / {{ tipoPrecio }}€
            ({{ tipoSocio }})
          </div>
          <div v-else>
            Siguiente pago del {{ pagoSiguienteFechaInicial }} al
            {{ pagoSiguienteFechaFinal }}
            <div class="">
              <small class="text-danger"
                >Configura una tarjeta para poder hacer el pago
                automático.</small
              >
            </div>
          </div>
        </div>
      </div>
      <div class="v-list__item">
        <div class="v-list__label">Estado del pago</div>
        <div class="v-list__content">
          <div v-if="pagoEstado">
            <i class="bi bi-check-lg text-success"></i> Pagado
          </div>
          <div v-else>
            <i class="bi bi-exclamation-circle-fill text-danger"></i> No pagado
          </div>
        </div>
      </div>
    </div>
    <div class="card__actions">
      <Modal id="modalPagos">
        <template #titulo>
          <h5 class="modal-title">
            Forma de pago
          </h5>
        </template>
        <PerfilPagosEdit></PerfilPagosEdit>
        <template #footer>
          <button
            type="button"
            class="boton boton--gris"
            data-bs-dismiss="modal"
          >
            Cerrar
          </button>
          <button type="button" class="boton boton--principal">
            Guardar cambios
          </button>
        </template>
      </Modal>
      <button
        type="button"
        class="boton boton--principal boton--block"
        data-bs-toggle="modal"
        data-bs-target="#modalPagos"
      >
        Modificar forma de pago
      </button>
    </div>
  </div>
</template>
<script lang="ts">
import { computed, defineComponent } from "vue";
import Modal from "@/components/ui/Modal.vue";
import PerfilPagosEdit from "@/views/perfil/components/PerfilPagosEdit.vue";
import { useStore } from "@/store";

export default defineComponent({
  name: "PerfilPagos",
  components: {
    Modal,
    PerfilPagosEdit,
  },
  setup() {
    const store = useStore();

    return {
      pagoSiguienteFechaInicial: "07/09/2021",
      pagoSiguienteFechaFinal: "30/09/2021",
      tarjetaNumero: "0000 0000 0000 0000",
      tarjetaCaduca: "11/23",
      tipoPrecio: computed(() => store.state.user.perfil.tipo_socio.precio),
      tipoSocio: computed(() => store.state.user.perfil.tipo_socio.descripcion),
      pagoEstado: computed(() => store.state.user.perfil.pagos.status),
      tarjetaGuardada: computed(() => store.state.user.perfil.pagos.fpag_saved), // Tarjeta guardada o pago manual
    };
  },
});
</script>
<style lang="scss"></style>
