
import { defineComponent, onBeforeMount } from "vue";
import NavBarResponsive from "@/components/layout/NavBarResponsive.vue";
import TopBar from "@/components/layout/TopBar.vue";
import { store, useStore } from "./store";

export default defineComponent({
  name: "Perfil",
  components: {
    NavBarResponsive,
    TopBar,
  },

  watch: {
    $route(to, from) {
      document.title = to.meta.title || "Tot a Orri App";
    },
  },

  setup() {
    const store = useStore();

    console.log("Iniciando apliacion");

    onBeforeMount(() => {
      if (store.getters.isAuthenticated) {
        store.commit("auth/loggedIn");
      }

      // Cargar perfil del usuario si esta login
      if (!store.state.user.perfil.nombre && store.state.auth.loggedIn) {
        console.log("Obteniendo perfil");
        store.dispatch("user/loggedIn");
      }
    });

    return {
      entityName: store.state.entityName,
      entityType: store.state.entityType,
    };
  },
});
