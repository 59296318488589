<template>
  <div class="mb-3">
    <label for="labelNombre" class="form-label">Nombre</label>
    <input
      type="text"
      class="form-control"
      id="labelNombre"
      placeholder="Nombre"
      :value="editedPerfil.nombre"
      disabled
    />
  </div>
  <div class="mb-3">
    <label for="labelPrimerApellido" class="form-label">Primer Apellido</label>
    <input
      type="text"
      class="form-control"
      id="labelPrimerApellido"
      placeholder="Primer Apellido"
      :value="editedPerfil.apellido_1"
      disabled
    />
  </div>
  <div class="mb-3">
    <label for="labelSegundoApellido" class="form-label"
      >Segundo Apellido</label
    >
    <input
      type="text"
      class="form-control"
      id="labelSegundoApellido"
      placeholder="Segundo Apellido"
      :value="editedPerfil.apellido_2"
      disabled
    />
  </div>
  <div class="mb-3">
    <label for="labelDni" class="form-label">DNI</label>
    <input
      type="text"
      class="form-control"
      id="labelDni"
      placeholder="Tu DNI/NIE"
      v-model="editedPerfil.dni"
      disabled
    />
  </div>
  <div class="mb-3">
    <Modal :id="`modalDNIA-${perfil.id}`">
      <template #titulo>
        <h5 class="modal-title">
          DNI Scanner
        </h5>
      </template>
      <p>works</p>
      <template #footer>
        <button type="button" class="boton boton--gris" data-bs-dismiss="modal">
          Cerrar
        </button>
        <button type="button" class="boton boton--principal">
          Guardar cambios
        </button>
      </template>
    </Modal>
    <!-- <button
      type="button"
      class="boton boton--principal boton--sm me-2"
      data-bs-toggle="modal"
      :data-bs-target="`#modalDNIA-${perfil.id}`"
    >
      Subir DNI Cara A
    </button>
    <span class="boton boton--principal boton--sm me-2">
      Subir DNI Cara A
    </span> -->
  </div>
  <div class="mb-3">
    <label for="labelFechanacimiento" class="form-label"
      >Fecha de Nacimiento</label
    >
    <input
      type="date"
      class="form-control"
      id="labelFechanacimiento"
      placeholder="Segundo Apellido"
      v-model="editedPerfil.fecha_naci"
      disabled
    />
  </div>
  <div class="mb-3">
    <label for="labelFechanacimiento" class="form-label">Genero</label>
    <select
      class="form-select"
      aria-label="Default select example"
      v-model="editedPerfil.genero"
    >
      <option selected></option>
      <option value="m">Masculino</option>
      <option value="f">Femenino</option>
      <option value="o">Otras opciones o no binario</option>
    </select>
  </div>
  <div class="mb-3">
    <label for="labelPhone" class="form-label">Email</label>
    <input
      type="text"
      class="form-control"
      id="labelEmail"
      placeholder="Email"
      v-model="editedPerfil.email"
    />
    <div id="labelEmail" class="form-text">
      Asegurate de escribirlo bien, se usa para recuperar tu contraseña
    </div>
  </div>
  <div class="mb-3">
    <label for="labelPhone" class="form-label">Teléfono</label>
    <input
      v-once
      v-maska="'+(34) ### ### ###'"
      type="text"
      class="form-control"
      id="labelPhone"
      placeholder="Teléfono"
      :value="editedPerfil.telefono"
      @maska="(el) => (editedPerfil.telefono = el.target.dataset.maskRawValue)"
    />
    <div id="labelPhone" class="form-text">
      Lo usamos para enviar notificaciones de WhatsApp
    </div>
  </div>

  <div v-if="isFamiliar" class="mb-3 text-danger" :isFamiliar="true">
    <label for="labelBorrar" class="form-label"
      >Eliminar familiar de tu cuenta</label
    >
    <input
      type="text"
      class="form-control"
      id="labelBorrar"
      placeholder="BORRAR"
      v-model="confirmDeleteFamiliar"
    />
    <div id="labelPhone" class="form-text">
      Escribe "BORRAR" para eliminar este familiar de tu cuenta
    </div>
    <div v-if="confirmDeleteFamiliar === 'BORRAR'" class="mt-2">
      <button
        class="boton boton--danger boton--block"
        @click="deleteFamiliar"
        data-bs-dismiss="modal"
      >
        <strong><i class="bi bi-trash-fill"></i> Borrar Familiar </strong>
        {{ editedPerfil.nombre }}
      </button>
    </div>
  </div>
</template>
<script lang="ts">
import { computed, defineComponent, PropType, ref } from "vue";
import { useStore } from "@/store";
import Modal from "@/components/ui/Modal.vue";

import { User } from "@/store/modules/user/interfaces";
import PerfilScannerDNI from "@/views/perfil/components/PerfilScannerDNI.vue";

export default defineComponent({
  name: "PerfilEdit",
  components: {
    Modal,
    PerfilScannerDNI,
  },
  props: {
    perfil: {
      type: Object as PropType<User>,
      default: {},
    },
    isFamiliar: {
      type: Boolean,
      default: false,
    },
    isOnboarding: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["onSaveProfile"],
  setup(props, context) {
    const store = useStore();
    const editedPerfil = ref(computed(() => props.perfil));
    const telefono = ref("");

    const confirmDeleteFamiliar = ref("");

    // const savePerfil = () => {
    //   editedPerfil.value.telefono = telefono.value;
    //   context.emit('onSaveProfile', editedPerfil.value);
    // }

    const savePerfil = async () => {
      console.log("Guardando perfil editado", editedPerfil.value);

      // Modificar un familiar
      if (props.isFamiliar) {
        const success = await store.dispatch(
          "user/editFamiliar",
          editedPerfil.value
        );
        if (success) {
          console.log("Familiar actualizado correctamente");
        } else {
          console.log("Error al actualizar el familiar");
        }

        context.emit("onSaveProfile", editedPerfil.value);
        return success;
      }

      // Modificar perfil propio
      if (!props.isFamiliar) {
        // Check if isOnboarding
        if (props.isOnboarding) {
          editedPerfil.value.onboarding_ok = true;
        }

        const success = await store.dispatch(
          "user/editProfile",
          editedPerfil.value
        );
        if (success) {
          console.log("Perfil actualizado correctamente");
        } else {
          console.log("Error al actualizar el perfil");
        }

        context.emit("onSaveProfile", editedPerfil.value);
        return success;
      }
    };

    const deleteFamiliar = async () => {
      if (!props.isFamiliar) {
        return false;
      }

      const success = await store.dispatch(
        "user/deleteFamiliar",
        editedPerfil.value
      );
    };

    return {
      isFamiliar: computed(() => props.isFamiliar),
      editedPerfil,
      telefono,
      confirmDeleteFamiliar,
      savePerfil,
      deleteFamiliar,
    };
  },
});
</script>
<style lang="scss"></style>
