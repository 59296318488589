
import { defineComponent, PropType, toRaw } from "vue";
import Modal from "@/components/ui/Modal.vue";
import TicketsTicketSummary from "@/views/tickets/components/TicketsTicketSummary.vue";
import TicketsTicketSummarySocios from "@/views/tickets/components/TicketsTicketSummarySocios.vue";
import { Ticket } from "@/models/tienda/Ticket";

export default defineComponent({
  name: "TicketsTicket",
  components: {
    Modal,
    TicketsTicketSummary,
    TicketsTicketSummarySocios,
  },
  props: {
    ticket: {
      type: Object as PropType<Ticket[]>,
      required: true,
    },
  },
  setup(props) {
    const tickets = toRaw(props.ticket);

    const ticketSummary = {
      id: tickets[0].id,
      descripcion: tickets[0].descripcion,
      total: tickets.map((ticket) => ticket.importe).reduce((a, b) => a + b),
    }

    return {
      tickets,
      ticketSummary,
    };
  }
});
