import { createApp } from "vue";

import Maska from "maska";

import "./registerServiceWorker";
import { key, store } from "./store";
import router from "./router";
import interceptor from "./services/interceptor";
import App from "./App.vue";

import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "/node_modules/bootstrap-icons/font/bootstrap-icons.css";
import "@/scss/main.scss";

// Iniciar Interceptor axios
interceptor.init();

createApp(App)
  .use(store, key)
  .use(router)
  .use(Maska)
  .mount("#app");
