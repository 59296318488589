<template>
  <section class="wrapper">
    <div class="container">
      <Title pretitle="Buenos días" :title="`Hola, ${username}`"></Title>
      <HomeDashboard></HomeDashboard>
    </div>
  </section>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";
import NavBarResponsive from "@/components/layout/NavBarResponsive.vue";
import Title from "@/components/layout/Title.vue";
import HomeDashboard from "@/views/home/components/HomeDashboard.vue";
import { useStore } from "@/store";

export default defineComponent({
  name: "Home",
  components: {
    NavBarResponsive,
    HomeDashboard,
    Title,
  },
  setup() {
    const store = useStore();

    return {
      username: computed(() => store.state.user.perfil.nombre || '....')
    }
  }
});
</script>
