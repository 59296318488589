import { Action } from "vuex";
import axiosApi from "@/services/axios";

import { State } from "@/store";
import { UserState } from "./interfaces";

export const loggedIn: Action<UserState, State> = async ({ commit, dispatch }) => {
    commit('setLoading', true);
    await dispatch('getProfile');
    await dispatch('getFamilia');
}

export const getProfile: Action<UserState, State> = async ({ commit, dispatch }, payload) => {
    const res = await axiosApi.get('/profile').then(res => res.data);
    commit('setProfile', res.user);
}

export const editProfile: Action<UserState, State> = async ({ commit, dispatch }, payload) => {
    const res = await axiosApi.post(`/profile/edit`, payload)
        .then(r => r.data);
    
    if (res.ok) {
        await dispatch('getProfile');
        return true;
    }

    return false;
}

export const changePassword: Action<UserState, State> = async ({ commit, dispatch }, payload) => {
    const res = await axiosApi.post(`/profile/edit/password`, payload)
        .then(r => r.data);
    
    if (res.ok) {
        return true;
    }

    return false;
}

export const getFamilia: Action<UserState, State> = async ({ commit }) => {
    const familia = await axiosApi.get('/familia').then(res => res.data);
    commit('setFamilia', familia);
}

export const editFamiliar: Action<UserState, State> = async ({ commit, dispatch }, payload) => {
    const res = await axiosApi.post(`/familia/edit/${payload.id}`, payload)
        .then(r => r.data);
    
    if (res.ok) {
        await dispatch('getFamilia');
        return true;
    }

    return false;
}

export const deleteFamiliar: Action<UserState, State> = async ({ commit, dispatch }, payload) => {
    const res = await axiosApi.post(`/familia/delete/${payload.id}`)
        .then(r => r.data);
    
    if (res.ok) {
        await dispatch('getFamilia');
        return true;
    }

    return false;
}


export const getTarifas: Action<UserState, State> = async ({ commit, dispatch }, payload) => {
    const res = await axiosApi.get(`/socio/tarifas?socio=${payload.id}`)
        .then(r => r.data);
    
    if (res.ok) {
        return res.tarifas;
    }

    return false;
}

export const getCuotasConfig: Action<UserState, State> = async ({ commit, dispatch }, payload) => {
    const res = await axiosApi.get(`/cuotas/conf`)
        .then(r => r.data);
    
    if (res.ok) {
        return res.config;
    }

    return {};
}

export const pagarCuota:  Action<UserState, State> = async ({ commit, dispatch }, payload) => {
    console.log('Realizando pago', payload);
    
    const res = await axiosApi.post(`/pagos/redirectPay`, payload)
        .then(r => r.data);

    if (res.ok) {
        return res;
    }

    return false;
}

export const pagarProducto:  Action<UserState, State> = async ({ commit, dispatch }, payload) => {
    console.log('realizando pago tienda', payload);
    
    const res = await axiosApi.post(`/pagos/redirectPay`, payload)
        .then(r => r.data);

    if (res.ok) {
        return res;
    }

    return false;
}

export const getTickets: Action<UserState, State> = async ({ commit, dispatch }, payload) => {
    const tickets = await axiosApi.get('/store/tickets').then(res => res.data);
    
    commit('setTickets', tickets);
    return tickets;
}