<template>
  <nav class="footer-nav">
    <div class="footer-nav__items">
      <div class="footer-nav__item">
        <router-link to="/" class="footer-nav__link">
          <i class="bi bi-house-door-fill"></i>
        </router-link>
      </div>
      <div class="footer-nav__item">
        <router-link to="/tienda" class="footer-nav__link">
          <i class="bi bi-shop"></i>
        </router-link>
      </div>

      <div class="footer-nav__item">
        <router-link to="/tickets" class="footer-nav__link">
          <i class="bi bi-receipt"></i>
        </router-link>
      </div>

      <div class="footer-nav__item">
        <router-link to="/perfil/familia" class="footer-nav__link">
          <i class="bi bi-person-plus-fill"></i>
        </router-link>
      </div>
      <div class="footer-nav__item">
        <router-link to="/perfil" class="footer-nav__link">
          <i class="bi bi-person-bounding-box"></i>
        </router-link>
      </div>
    </div>
  </nav>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "NavBarResponsive",
});
</script>

<style lang="scss"></style>
