<template>
  <nav class="top-nav">
    <div class="top-nav__left">
      <span
        class="top-nav__back"
        @click="
          $route.path.startsWith('/redsys-respuesta')
            ? $router.replace('/')
            : $router.go(-1)
        "
        v-show="$route.path == '/' ? false : true"
      >
        <i class="bi bi-chevron-left"></i>
      </span>
    </div>
    <div class="top-nav__center">
      <span>{{
        $route.name ?? `${store.state.entityType} ${store.state.entityName}`
      }}</span>
    </div>
    <div class="top-nav__right">
      <span class="top-nav__logout" @click="logout">
        <i class="bi bi-box-arrow-right"></i>
      </span>
    </div>
  </nav>
</template>
<script lang="ts">
import { useStore } from "@/store";
import { defineComponent, onMounted, onUnmounted } from "vue";
export default defineComponent({
  name: "TopBar",
  props: {
    topTitle: String,
  },
  setup() {
    const store = useStore();

    const logout = () => {
      store.dispatch("auth/logout");
    };

    const handleSCroll = (event: any) => {
      let header = document.querySelector(".top-nav");
      if (
        header &&
        window.scrollY > 20 &&
        !header.className.includes("v-toolbar--bgchange")
      ) {
        // console.log(window.scrollY);
        header.classList.add("top-nav--scroll");
      } else if (header && window.scrollY < 20) {
        header.classList.remove("top-nav--scroll");
      }
    };

    onMounted(() => {
      window.addEventListener("scroll", handleSCroll);
    });

    onUnmounted(() => {
      window.removeEventListener("scroll", handleSCroll);
    });

    return {
      store,
      logout,
      handleSCroll,
    };
  },
});
</script>
<style lang="scss"></style>
