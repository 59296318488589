
import { useStore } from "@/store";
import { TipoSocio } from "@/store/modules/user/interfaces";
import { defineComponent, onBeforeMount, PropType, reactive, Ref, ref, toRaw, toRefs, unref, watch } from "vue";
export default defineComponent({
  name: "SelectUser",
  props: {
    idSocio: Number,
    nombre: String,
    apellido_1: String,
    apellido_2: String,
    dni: String,
    tarifa: Object as PropType<TipoSocio>,
    status: Boolean
  },
  emits: ['changeSelected'],
  setup(props, context) {
    const { idSocio, nombre, apellido_1, apellido_2, dni, tarifa, status } = props;

    const store = useStore();

    const renovarSocio = ref(true);
    const renovarTipoSocio = ref(true);
    const currentTarifa = ref(tarifa);
    let tarifas = ref([] as any[]);

    const onChangeSelected = () => {
      context.emit('changeSelected', {
        id: idSocio,
        tarifa: toRaw(currentTarifa.value),
        renueva: renovarSocio.value
      });
    }

    const cambiarTarifa = () => {
      tarifas.value = tarifas.value.map(tar => {
        if (!tar.current) {
          currentTarifa.value = toRaw(tar);
          onChangeSelected();
        }

        tar.current = !tar.current;

        return toRaw(tar);
      })
    }

    watch(renovarSocio, (newValue, prevValue) => {
      context.emit('changeSelected', {
        id: idSocio,
        tarifa: toRaw(currentTarifa.value),
        renueva: newValue
      })
    });

    onBeforeMount(() => {
      // Si el socio ya ha pagado, no hacer nada
      if (status === true) {
        return;
      }

      // emitir changeSelected al montar por primera vez
      onChangeSelected();

      // Obtener otras tarifas del socio
      store.dispatch('user/getTarifas', { id: idSocio }).then((res: any[]) => {
        tarifas.value = res.map(tar => {
          // tar.current = tar.id === tarifa?.id;
          tar.current = res.length === 1 ? true : tar.default;
          if (tar.current) {
            currentTarifa.value = toRaw(tar);
            onChangeSelected();
          }
          return tar;
        }).sort((a, b) => a.current ? -1 : 0);

        console.log(`Tarifas Socio ${idSocio}`, tarifas.value);
      });
    })

    return {
      status,
      nombre, apellido_1, apellido_2, dni,
      renovarSocio,
      renovarTipoSocio,
      tarifas,
      cambiarTarifa
    }
  }
});
