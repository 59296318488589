
import { defineComponent } from "vue";
import { useStore } from "@/store";
import CreditCard from "@/views/perfil/components/CreditCard.vue";

export default defineComponent({
  name: "PerfilPagosEdit",
  components: {
    CreditCard,
  },
  setup() {
    const store = useStore();

    return {
      entityName: store.state.entityName,
      entityType: store.state.entityType,
    };
  },
});
