<template>
  <div class="card card--card">
    <h2 class="head-3">
      Contacto
    </h2>
    <p>
      Para cualquier incidencia o consulta, contacta a través de nuestro
      WhatsApp
    </p>
    <a href="https://wa.me/34644206025" class="boton boton--whatsapp"
      ><i class="bi bi-whatsapp me-1"></i> Enviar WhatsApp</a
    >
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "PerfilContacto",
});
</script>
<style lang=""></style>
