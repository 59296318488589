
import { computed, defineComponent } from "vue";
import { useStore } from "@/store";

export default defineComponent({
  name: "CardSocio",
  props: {
    idSocio: Number,
    nombre: String,
    apellido1: String,
    apellido2: String,
    tipoSocio: String,
    estadoSocio: Boolean,
    parent: Number,
  },
  setup() {
    const store = useStore();
    const currentUser = computed(() => store.state.user.perfil.id);
    
    return {
      entityName: store.state.entityName,
      entityType: store.state.entityType,
      currentUser
    }
  }
});
