
import { Ticket } from "@/models/tienda/Ticket";
import tiendaService from "@/services/api/tiendaService";
import { useStore } from "@/store";
import { defineComponent, onMounted, PropType, ref } from "vue";
import TicketsTicketSummaryVue from "./TicketsTicketSummary.vue";

export default defineComponent({
  name: "TicketsTicketSummarySocios",
  components: {},
  props: {
    tickets: {
      type: Object as PropType<Ticket[]>,
      default: () => [],
    },
  },
  setup(props) {
    const store = useStore();
    const socio = store.state.user.perfil;
    const urlsQr = ref([] as any[]);

    const socioTicket: Ticket =
      props.tickets.find((ticket) => ticket.socio.id === socio.id) ??
      ({} as Ticket);

    const familiarTicket: Ticket[] = props.tickets.filter(
      (ticket) => ticket.socio.id !== socio.id
    );

    const loadQrImages = async () => {
      // Socio QR
      urlsQr.value.push({
        id: socio.id,
        qrUrl: await tiendaService.getTicketQrImage(socioTicket.id),
      });

      // Familia QR
      familiarTicket.forEach(async (ticket) => {
        urlsQr.value.push({
          id: ticket.socio.id,
          qrUrl: await tiendaService.getTicketQrImage(ticket.id),
        });
      });
    };

    const getQrUrlBySocioId = (socioId: number) => {
      return urlsQr.value.find((url) => url.id === socioId)?.qrUrl;
    };

    onMounted(() => {
      loadQrImages();
    });

    return {
      socioTicket,
      familiarTicket,
      getQrUrlBySocioId,
    };
  },
});
