<template>
  <div class="card card--card">
    <h2 class="head-3">
      {{ perfil?.nombre }} {{ perfil?.apellido_1 }} {{ perfil?.apellido_2 }}
    </h2>

    <div class="datos">
      <div class="datos__item">
        <div class="datos__label">Nacimiento</div>
        <div class="datos__content">{{ perfil?.fecha_naci }} ({{ edad }})</div>
      </div>
      <div class="datos__item">
        <div class="datos__label">DNI</div>
        <div class="datos__content">{{ perfil?.dni }}</div>
      </div>
      <div v-if="perfil?.poblacion?.length > 0" class="datos__item">
        <div class="datos__label">Población</div>
        <div class="datos__content">{{ perfil?.poblacion }}</div>
      </div>
      <div class="datos__item">
        <div class="datos__label">Teléfono</div>
        <div class="datos__content">{{ perfil?.telefono }}</div>
      </div>
      <div class="datos__item">
        <div class="datos__label">Email</div>
        <div class="datos__content">{{ perfil?.email }}</div>
      </div>
      <div class="datos__item">
        <div class="datos__label">Género</div>
        <div class="datos__content">
          <span v-if="perfil?.genero == 'm'">Masculino</span>
          <span v-else-if="perfil?.genero == 'f'">Femenino</span>
          <span v-else-if="perfil?.genero == 'o'"
            >Otras opciones o no binario</span
          >
        </div>
      </div>
      <div class="datos__item">
        <div class="datos__label">Tipo</div>
        <div class="datos__content">
          <span
            class="badge"
            v-bind:class="{
              'badge--verde': perfil?.tipo_socio.id == 1,
              'badge--azul': perfil?.tipo_socio.id == 2,
              'badge--naranja': perfil?.tipo_socio.id == 3,
              'badge--rojo': perfil?.tipo_socio.id == 4,
            }"
          >
            {{ perfil?.tipo_socio.descripcion }}
          </span>
        </div>
      </div>
    </div>
    <div class="card__actions">
      <Modal :id="`modal-${perfil.id}`">
        <template #titulo>
          <h5 class="modal-title">
            Modifica tus datos
          </h5>
        </template>
        <PerfilFichaEdit
          :perfil="perfil"
          :isFamiliar="isFamiliar"
          ref="modalEditPerfil"
        ></PerfilFichaEdit>
        <template #footer>
          <button
            type="button"
            class="boton boton--gris"
            data-bs-dismiss="modal"
          >
            Cerrar
          </button>
          <button
            type="button"
            class="boton boton--principal"
            data-bs-dismiss="modal"
            @click="modalEditPerfil.savePerfil()"
          >
            Guardar cambios
          </button>
        </template>
      </Modal>
      <button
        type="button"
        class="boton boton--principal boton--block"
        data-bs-toggle="modal"
        :data-bs-target="`#modal-${perfil.id}`"
      >
        Modificar datos
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, ref } from "vue";
import { User } from "@/store/modules/user/interfaces";
import { formatDistanceToNow } from "date-fns";
import { es } from "date-fns/locale";

import PerfilFichaEdit from "@/views/perfil/components/PerfilFichaEdit.vue";
import Modal from "@/components/ui/Modal.vue";

export default defineComponent({
  name: "PerfilFicha",
  components: {
    PerfilFichaEdit,
    Modal,
  },
  props: {
    perfil: {
      type: Object as PropType<User>,
      default: {},
    },
    isFamiliar: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const isFamiliar = computed(() => props.isFamiliar);
    const modalEditPerfil = ref();
    const perfil = computed(() => props.perfil);
    const edad = computed(() => {
      if (!props.perfil.fecha_naci) {
        return "...";
      }
      return formatDistanceToNow(new Date(props.perfil.fecha_naci), {
        locale: es,
      });
    });

    return {
      isFamiliar,
      perfil,
      edad,
      modalEditPerfil
    };
  },
});
</script>
<style lang=""></style>
