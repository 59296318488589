<template>
  <div class="add-socios mb-4">
    <div class="add-socios__item">
      <div class="add-socios__socio">
        <div class="form-check form-switch form-switch-md">
          <input
            class="form-check-input"
            type="checkbox"
            :id="`flexSwitchCheckDefault-${socio.dni}`"
            v-model="addSocio"
            :disabled="isAlreadyPurchased() || !socio.pagos.status || !available"
          />
          <label
            class="form-check-label"
            :for="`flexSwitchCheckDefault-${socio.dni}`"
          >
            {{ socio.nombre }} {{ socio.apellido_1 }} {{ socio.apellido_2 }}
            <div class="add-socios__price">
              <span>
                <strong v-if="isAlreadyPurchased() || !socio.pagos.status || !available">
                  {{ available ? 'Ya comprado' : 'No disponible' }}
                </strong>
                <strong v-else>
                  {{ producto?.pvp?.find(pvp => pvp.id_tarifa === socio.tipo_socio.id)?.importe }} €
                </strong>
                ({{ socio.tipo_socio?.descripcion }})
              </span>
            </div>
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onBeforeMount, PropType, ref, toRaw, watch } from "vue";
import { useStore } from "@/store";
import { User } from "@/store/modules/user/interfaces";
import { Producto } from "@/models/tienda/Producto";
import { Ticket } from "@/models/tienda/Ticket";

export default defineComponent({
  name: "Tienda",
  components: {},
  props: {
    socio: {
      type: Object as PropType<User>,
      default: {},
    },
    producto: {
      type: Object as PropType<Producto>,
      default: {},
    }
  },
  emits: ['changeSelected'],
  setup({socio, producto}, {emit}) {
    const store = useStore();
    const addSocio = ref(false);
    const tickets = store.state.user.tickets;
    const available = ref(false);
    
    const isAlreadyPurchased = () => {
      const ticket = tickets.find(ticket => {
        const ticketProducto = ticket.filter(
          ticketProducto => ticketProducto.id_producto === producto.id && ticketProducto.id_socio === socio.id
        );

        return ticketProducto.length > 0;
      })

      return ticket !== undefined;
    }

    const isProductAvailable = () => {
      producto.pvp.forEach(pvp => {
        if (pvp.id_tarifa === socio.tipo_socio.id) {
          available.value = true;
        }
      });
    }

    onBeforeMount(() => {
      isProductAvailable();
    });

    // Emitir evento al cambiar la seleccion de un socio
    watch(addSocio, () => {
      emit('changeSelected', {
        socio: toRaw(socio),
        importe: parseFloat(producto.pvp.find(pvp => pvp.id_tarifa === socio.tipo_socio.id)?.importe as string),
        enabled: toRaw(addSocio.value)
      })
    })

    return {
      entityName: store.state.entityName,
      entityType: store.state.entityType,
      available,
      isAlreadyPurchased,
      addSocio
    };
  },
});
</script>

<style lang="scss"></style>
