<template>
  <div class="card card--card">
    <h2 class="head-3">
      Contraseña
    </h2>
    <Modal id="modalPassword">
      <template #titulo>
        <h5 class="modal-title">
          Elige nueva contraseña
        </h5>
      </template>

      <Nota v-if="isPasswordReset"
        msg-nota-titutlo="Ok!"
        msg-nota="Contraseña cambiada correctamente!"
      ></Nota>

      <div v-if="!isPasswordReset">
        <p class="text-center">
          Introduce la nueva contraseña para tu usuario, con almenos 6
          caracteres, 1 mayuscula y 1 número.
        </p>

        <Nota v-if="error.length > 0" msgNotaTitutlo="Error" :msgNota="error"></Nota>

        <div class="mb-2">
          <label class="form-label">Elige tu nueva contraseña</label>
          <input type="password" class="form-control" v-model="password" />
        </div>
        <div class="mb-2">
          <label class="form-label">Repite la contraseña</label>
          <input type="password" class="form-control" v-model="password_confirmation" />
        </div>
      </div>
      
      <template #footer>
        <button type="button" class="boton boton--gris" data-bs-dismiss="modal" @click="resetModal">
          Cerrar
        </button>
        <button
          v-if="!isPasswordReset"
          type="button" class="boton boton--principal"
          @click="resetPassword"
          :class="[validatePassword() ? 'boton--principal' : 'boton--gris']"
          :disabled="!validatePassword() && isReseting"
        >
          {{ isReseting ? 'Guardando...' : 'Guardar cambios' }}
        </button>
      </template>
    </Modal>
    <button
      type="button"
      class="boton boton--principal boton--block"
      data-bs-toggle="modal"
      data-bs-target="#modalPassword"
    >
      Cambio de contraseña
    </button>
  </div>
</template>
<script lang="ts">
import { defineComponent, nextTick, ref } from "vue";
import Modal from "@/components/ui/Modal.vue";
import Nota from "@/components/alerts/Nota.vue";
import { useStore } from "@/store";

export default defineComponent({
  name: "PerfilPassword",
  components: {
    Modal,
    Nota
  },
  setup() {
    const store = useStore();

    const password = ref('');
    const password_confirmation = ref('');
    const isReseting = ref(false);
    const isPasswordReset = ref(false);
    const error = ref('');

    const validatePassword = () => {
      const samePassword = password.value === password_confirmation.value;
      const lengthPassword = password.value.trim().length >= 6;
      const hasUpperCase = /[A-Z]/.test(password.value);
      const hasLowerCase = /[a-z]/.test(password.value);
      const hasNumber = /[0-9]/.test(password.value);
      
      if (!samePassword) {
        error.value = 'Las contraseñas no coinciden'
      } else if (!lengthPassword) {
        error.value = 'La contraseña debe tener como minimo 6 caracteres'
      } else if (!hasUpperCase) {
        error.value = 'La contraseña debe tener al menos 1 mayuscula'
      } else if (!hasLowerCase) {
        error.value = 'La contraseña debe tener al menos 1 minuscula'
      } else if (!hasNumber) {
        error.value = 'La contraseña debe tener al menos 1 número'
      } else {
        error.value = '';
        return true;
      }

      return false;
    }

    // Enviar peticion de cambio de contraseña
    const resetPassword = async () => {
      if (!validatePassword()) {
        return false;
      }

      isReseting.value = true;
      const success = await store.dispatch('user/changePassword', {
        password: password.value,
        password_confirmation: password_confirmation.value
      });

      if (success) {
        isPasswordReset.value = true;
      } else {
        error.value = 'Error al cambiar la contraseña';
      }
      isReseting.value = false;
    }

    const resetModal = () => {
      nextTick(() => {
        password.value = '';
        password_confirmation.value = '';
        isReseting.value = false;
        isPasswordReset.value = false;
        error.value = '';
      })
    }

    return {
      password,
      password_confirmation,
      isReseting,
      isPasswordReset,
      error,
      validatePassword,
      resetPassword,
      resetModal
    }
  }
});
</script>
<style lang="scss"></style>
