import axios from "@/services/axios";
import { AxiosResponse } from "axios";

export default {

    async getProductos() {
        return axios.get('/store/products').then(res => res.data);
    },

    async getTickets() {
        return axios.get('/store/tickets').then(res => res.data);
    },

    async getTicketQrImage(ticketId: string | number) {
        const blobImage = await axios.get(`/store/tickets/${ticketId}/qr`, {
            responseType: 'blob'
        }).then(res => res.data);

        return URL.createObjectURL(blobImage);
    }

};
