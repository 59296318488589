<template>
  <section class="wrapper">
    <div class="container">
      <Title pretitle="Suscripción" :title="`${entityType} ${entityName}`"></Title>

      <div class="error-pago mb-5" v-if="pagos">
        <Nota
          :msgNotaTitutlo="`Pago de cuotas ${config.inicio_cuotas} hasta ${config.fin_cuotas}`"
          :msgNota="`Es hora de pagar la cuota ${!perfil.pagos.fpag_saved ? ', no tienes el pago automatico configurado' : ''}`"
        ></Nota>

        <Modal id="modalPago" ref="modalPago">
          <template #titulo>
            <h5 class="modal-title">Págo online Socios</h5>
          </template>

          <div v-show="!procesandoPago">
            <SelectUser
              v-for="socio in socios"
              :idSocio="socio.id"
              :nombre="socio.nombre"
              :apellido_1="socio.apellido_1"
              :apellido_2="socio.apellido_2"
              :dni="socio.dni"
              :tarifa="socio.tipo_socio"
              :status="socio.pagos.status"
              :key="socio.id"
              @change-selected="updateImporte"
            ></SelectUser>

            <div class="totals">
              <strong>Total:</strong>
              {{ importe }}
              <sup>€</sup>
            </div>

            <CreditCard ref="fpagSocio" :light="true" />
          </div>

          <template v-if="procesandoPago">
            <div class="text-center py-5">
              <div class="spinner-grow text-primary mb-4" role="status">
                <span class="visually-hidden">Espere porfavor...</span>
              </div><br/>
              <b>Redireccionando a pasarela de pagos</b>
            </div>
          </template>

          <template #footer>
            <button type="button" class="boton boton--gris" data-bs-dismiss="modal">Cerrar</button>
            <button
              v-if="fpagSocio.datosTarjeta?.titular !== '' && importe > 0 && !procesandoPago"
              type="button"
              class="boton boton--principal"
              @click="startPay"
            >Proceder al pago</button>
          </template>
        </Modal>

        <button
          type="button"
          class="boton boton--principal boton--block"
          :class="{'boton--gris': procesandoPago}"
          data-bs-toggle="modal"
          data-bs-target="#modalPago"
        >{{ procesandoPago ? 'Procesando pago...' : 'Pagar cuota de socio' }}</button>
      </div>

      <CardSocio
        v-for="(socio, index) in socios"
        :key="index"
        :id-socio="socio.id"
        :nombre="socio.nombre"
        :apellido1="socio.apellido_1"
        :apellido2="socio.apellido_2"
        :tipoSocio="socio.tipo_socio.descripcion"
        :estadoSocio="socio.pagos.status"
        :parent="socio.parent"
      ></CardSocio>
    </div>
  </section>
</template>

<script lang="ts">
import { computed, defineComponent, onBeforeMount, onMounted, ref, toRaw, watch } from "vue";
import { useStore } from "@/store";

import Title from "@/components/layout/Title.vue";
import CardSocio from "@/views/cuotas/components/CardSocio.vue";
import SelectUser from "@/views/perfil/components/SelectUser.vue";
import Modal from "@/components/ui/Modal.vue";
import Nota from "@/components/alerts/Nota.vue";
import CreditCard from "@/views/perfil/components/CreditCard.vue";
import { User } from "@/store/modules/user/interfaces";
import { useRouter } from "vue-router";
import { isAfter, isBefore, parse } from "date-fns";

export default defineComponent({
  name: "Cuotas",
  components: {
    Title,
    CardSocio,
    Nota,
    CreditCard,
    Modal,
    SelectUser,
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    const perfil = computed(() => store.state.user.perfil);

    const fpagSocio = ref({} as any);
    const sociosSelect = ref([] as any[]);
    const importe = ref(0);
    const procesandoPago = ref(false);

    const config = ref({} as any);

    const modalPago = ref();

    onBeforeMount(async () => {
      // Obtener familiares
      store.dispatch("user/getFamilia");
      config.value = await store.dispatch("user/getCuotasConfig");
    });

    const socios = computed((): User[] => {
      return [store.state.user.perfil, ...(store.state.user.familia ?? [])];
    });

    // Actualizar importes y socios a renovar
    const updateImporte = (payload: any) => {
      if (!payload.renueva) {
        sociosSelect.value = toRaw(sociosSelect.value.filter(socio => socio.id !== payload.id));
      } else {
        const socioIndex = sociosSelect.value.findIndex(socio => socio.id === payload.id);
        if (socioIndex !== -1) {
          sociosSelect.value[socioIndex] = payload;
        } else {
          sociosSelect.value.push(payload);
        }
      }

      importe.value = 0;
      sociosSelect.value?.forEach(socio => {
        importe.value += socio.tarifa.precio;
      })
    }

    // Iniciar pago con los socios que renuevan
    const startPay = async () => {
      procesandoPago.value = true;
      
      // Enviar peticion de pago
      const res = await store.dispatch('user/pagarCuota', {
        ...fpagSocio.value.datosTarjeta,
        socios: toRaw(sociosSelect.value),
        importe: importe.value,
      });

      console.log('respuesta api pagos', res);

      // Redireccionar si es mediante pasarela de pagos.
      // Creamos un form oculto para hacer la peticion
      const form = document.createElement('form');
      form.method = 'post';
      form.action = res.request.url;

      const params = {
        "Ds_Signature": res.request.ds_signature,
        "Ds_SignatureVersion": res.request.ds_signature_version,
        "Ds_MerchantParameters": res.request.ds_merchant_parameters
      };

      for (const [key, value] of Object.entries(params)) {
        const hiddenField = document.createElement('input');
        hiddenField.type = 'hidden';
        hiddenField.name = key;
        hiddenField.value = value;

        form.appendChild(hiddenField);
      }

      // Redireccion en misma ventana, ya que en otra distinta genera problemas de bloqueo popup
      window.document.body.appendChild(form);
      form.submit();

      console.log('Transaccion ID', res.transaccion_id);

      // Deshabilitado por problemas
      // pestaña redireccion a pasarela
      // const wHandle = window.open('', 'redsysPay');
      // wHandle?.document.body.appendChild(form);
      // form.submit();

      // Poll every 2 seconds to check pay, close window pay on result
      // Deshabilitado por problemas en navegadores movil y otros
      // let retries = 180; // 180 * 2 segundos = 7 minutos
      // const checkTxInterval = setInterval(async () => {
      //   const resStatus = (await axiosApi.get(`pagos/check/${res.transaccion_id}`)).data;
      //   retries--;
      //   console.log(resStatus);
      //   console.log('Reintentos restantes', retries);
      //   if (retries === 0 || resStatus.status > 0) {
      //     wHandle?.close();
      //     procesandoPago.value = false;
      //     modalPago.value.closeModal();
      //     clearInterval(checkTxInterval);

      //     // Success?
      //     if (resStatus.status === 1) {
      //       router.push('/redsys-respuesta');
      //     }

      //     // Obtener datos actualizados perfil
      //     store.dispatch('user/getProfile');
      //   }
      // }, 2000);
    }

    // Comprobar si es fecha de pago y si se necesita realizar el pago de algun socio
    const pagos = computed(() => {
      const current = new Date();
      const inicio = parse(config.value.inicio_cuotas, 'dd/MM/yyyy', new Date());
      const fin = parse(config.value.fin_cuotas, 'dd/MM/yyyy', new Date());
      
      let needPay = false;
      socios.value.forEach(socio => {
        if (!socio.pagos.status) {
          needPay = true;
        }
      });

      return isAfter(current, inicio) && isBefore(current, fin) && needPay;
    })

    return {
      entityName: store.state.entityName,
      entityType: store.state.entityType,
      config,
      perfil,
      socios,
      importe,
      fpagSocio,
      pagos,
      modalPago,
      procesandoPago,
      updateImporte,
      startPay,
    };
  },
});
</script>

<style lang=""></style>
