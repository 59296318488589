<template>
  <div class="container">
    <div class="login-box login-box--left">

      <!-- Loading, verificando link token -->
      <div v-if="isLoading" class="d-flex flex-column align-items-center gap-3">
        <div class="spinner-grow text-secondary" style="width: 3rem; height: 3rem;" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
        <b>Verificando link de recuperación...</b>
      </div>

      <!-- Link expirado o invalido -->
      <div v-if="!isLoading && !isValidToken">
        <div class="login-box__actions">
          <Nota msgNotaTitutlo="Link expirado" :msgNota="error"></Nota>
          <router-link to="/login"
            class="boton boton--principal boton--block mb-2"
          >Volver al login</router-link>
        </div>
      </div>

      <!-- Password cambiado con exito -->
      <div v-if="isPasswordReset" class="d-flex flex-column align-items-center gap-3">
        <Nota msgNotaTitutlo="ok!" msgNota="Contraseña restablecida correctamente!" />
        <router-link
          to="/login"
          class="boton boton--principal boton--block mb-2"
        >Volver al Login</router-link>
      </div>

      <div v-if="!isLoading && isValidToken && !isPasswordReset" class="login-box__content">
        <h1 class="login-box__h1 mb-2">Nueva contraseña</h1>
        <h2>Hola, {{ user.nombre }} {{ user.apellido_1 }}</h2>
        <p>
          Introduce la nueva contraseña para tu usuario, con almenos 6
          caracteres, 1 mayuscula y 1 número.
        </p>

        <Nota v-if="error.length > 0" msgNotaTitutlo="Error" :msgNota="error"></Nota>

        <div class="login-box__login">
          <div class="form-floating mb-3">
            <input
              type="password"
              class="form-control"
              id="password"
              placeholder="Nueva contraseña"
              v-model="password"
            />
            <label for="floatingInput">Nueva contraseña</label>
          </div>
          <div class="form-floating mb-3">
            <input
              type="password"
              class="form-control"
              id="password_confirm"
              placeholder="Nueva contraseña"
              v-model="password_confirmation"
            />
            <label for="floatingInput">Vuelvela a introducir</label>
          </div>
          <div class="login-box__actions">
            <button
              class="boton boton--principal boton--block mb-2"
              :class="[validatePassword() ? 'boton--principal' : 'boton--gris']"
              :disabled="!validatePassword() && isReseting"
              @click="resetPassword"
            >{{ isReseting ? 'Realizando petición..' : 'Crear contraseña' }}</button>
            <router-link to="/login" class="link">Volver al Inicio</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onBeforeMount, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import auth from "@/services/api/auth";
import Nota from "@/components/alerts/Nota.vue";
import { User } from "@/store/modules/user/interfaces";
import axios from "axios";

export default defineComponent({
  name: "LoginResetPassword",
  components: {
    Nota
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const token = route.params.token as string;
    const user = ref({} as User);
    const password = ref('');
    const password_confirmation = ref('');

    const isLoading = ref(true);
    const isValidToken = ref(false);
    const isReseting = ref(false);
    const isPasswordReset = ref(false);
    const error = ref("");

    onBeforeMount(async () => {
      console.log('verificando token');
      isLoading.value = true;
      let res: any;

      await new Promise((resolve, reject) => {
        setTimeout(async () => {
          res = await auth.verifyRecoveryToken(token);
          resolve(true);
        }, 3000)
      });

      // Si el token no es valido, lo indicamos y volvemos a la pagina de login
      if (!res.ok) {
        isValidToken.value = false;
        error.value = res.message;
        isLoading.value = false;
        return;
      }

      // Token valido
      console.log(res.user);
      user.value = res.user;
      isValidToken.value = true;
      isLoading.value = false;
    })

    const validatePassword = () => {
      const samePassword = password.value === password_confirmation.value;
      const lengthPassword = password.value.trim().length >= 6;
      const hasUpperCase = /[A-Z]/.test(password.value);
      const hasLowerCase = /[a-z]/.test(password.value);
      const hasNumber = /[0-9]/.test(password.value);
      
      if (!samePassword) {
        error.value = 'Las contraseñas no coinciden'
      } else if (!lengthPassword) {
        error.value = 'La contraseña debe tener como minimo 6 caracteres'
      } else if (!hasUpperCase) {
        error.value = 'La contraseña debe tener al menos 1 mayuscula'
      } else if (!hasLowerCase) {
        error.value = 'La contraseña debe tener al menos 1 minuscula'
      } else if (!hasNumber) {
        error.value = 'La contraseña debe tener al menos 1 número'
      } else {
        error.value = '';
        return true;
      }

      return false;
    }

    // Enviar peticion de cambio de contraseña
    const resetPassword = async () => {
      if (!validatePassword()) {
        return false;
      }

      console.log('Enviando peticion de cambio de contraseña');
      isReseting.value = true;
      const res = await auth.resetPassword(password.value, password_confirmation.value, token);
      if (res.ok) {
        isPasswordReset.value = true;
      } else {
        isValidToken.value = false;
        error.value = res.message;
      }
      isReseting.value = false;
      console.log('respuesta cambio de contraseña', res);
    }

    return {
      user,
      password,
      password_confirmation,
      validatePassword,
      resetPassword,
      isLoading,
      isValidToken,
      isReseting,
      isPasswordReset,
      error,
    };
  },
});
</script>
